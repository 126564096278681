import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import ScrollReveal from 'scrollreveal';
import { Link } from 'react-router-dom';
import Header from './Header';
import '../CSS/Global.css'
import Footer from './Footer';
import Logo from '../IMG/logo-removebg-preview.png'
import wcuImg from '../IMG/main4.webp'
import gridImg from '../IMG/main5.webp'
import gridImg2 from '../IMG/main6.webp'
import gridImg3 from '../IMG/main7.webp'
import gridImg4 from '../IMG/main8.webp'
import aboutImg1 from '../IMG/main9.webp'
import aboutImg2 from '../IMG/NN2NR7V.webp'
import facility1 from '../IMG/main10.webp'
import facility2 from '../IMG/main11.webp'
import facility3 from '../IMG/main12.webp'

const Home = () => {

    let login = JSON.parse(localStorage.getItem("login")) || ""
    useEffect(() => {
        animateProgressBars();

        // Initialize ScrollReveal
        ScrollReveal().reveal('.main', {
            origin: 'top',
            distance: '50px',
            duration: 1200,
            opacity: 0,
            scale: 1.05,
            easing: 'ease-in-out',
        });
    }, []);

    // Function to animate progress bars
    const animateProgressBars = () => {
        document.querySelectorAll('.wcu-pb').forEach(bar => {
            const progress = bar.getAttribute('data-progress');
            bar.querySelector('span').style.width = `${progress}%`;
        });
    };

    return (
        <>
            <Header />
            {/* Main Section */}
            <div className='bg-color'>
                <div className="main">
                    <div className="main-logo">
                        <img src={Logo} alt="logo" />
                    </div>
                    <div className="main-content">
                        <h2>The Birthplace of <span>Skate Greatness</span></h2>
                        <p>Radical Sports Skate Academy is a premier roller skate academy dedicated to training.</p>
                        <div className="main-btn">
                            <Link to="/admission">Join Our Academy</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Why Choose Us Section */}
            <div className="wcu-section">
                {/* Column 1 */}
                <div className="wcu-col">
                    <div className="wcu-sub">Why Choose Us</div>
                    <div className="wcu-slogan">Skate with <span>Style</span></div>
                    <div className="wcu-para">
                        Experience the thrill of skating like never before with our top-of-the-line equipment and accessories.
                    </div>
                    <div className="wcu-pb-container">
                        <div className="wcu-pb" data-progress="40"><span></span></div>
                        <div className="wcu-pb-label">Quality</div>
                    </div>
                    <div className="wcu-pb-container">
                        <div className="wcu-pb" data-progress="70"><span></span></div>
                        <div className="wcu-pb-label">Speed</div>
                    </div>
                    <div className="wcu-pb-container">
                        <div className="wcu-pb" data-progress="90"><span></span></div>
                        <div className="wcu-pb-label">Durability</div>
                    </div>
                </div>

                {/* Column 2 */}
                <div className="wcu-col col2">
                    <div className="wcu-img">
                        <img src={wcuImg} alt="Skate Image" className="wcu-img" />
                    </div>
                    <div className="detail">
                        <h4><CountUp end={8} duration={2} /></h4>
                        <p>Years of Rich History</p>
                    </div>
                </div>

                {/* Column 3 */}
                <div className="wcu-col">
                    <div className="wcu-counter" data-target="150">
                        <div className="wcu-group">
                            <i className="fas fa-thumbs-up"></i>
                            <div className="num"><CountUp end={10} duration={2} />+</div>
                        </div>
                        <div className="txt">Centers</div>
                    </div>
                    <div className="wcu-counter" data-target="25">
                        <div className="wcu-group">
                            <i className="fas fa-trophy"></i>
                            <div className="num"><CountUp end={1000} duration={2} />+</div>
                        </div>
                        <div className="txt">Awards</div>
                    </div>
                    <div className="wcu-counter" data-target="500">
                        <div className="wcu-group">
                            <i className="fa-solid fa-person-skating"></i>
                            <div className="num"><CountUp end={500} duration={2} />+</div>
                        </div>
                        <div className="txt">Skaters</div>
                    </div>
                </div>
            </div>

            {/* Programs Section */}
            <div className="program-grid" id="program">
                <div className="program-grid1">
                    <Link to="/enquiry">
                        <div className="program-grid-img">
                            <img src={gridImg} alt="Image 1" />
                        </div>
                        <div className="program-overlay">
                            <h3>Junior Elite Program</h3>
                            <p>Challenging established thinking to drive transformation in 2020.</p>
                        </div>
                    </Link>
                </div>

                <div className="program-grid2">
                    <Link to="/enquiry">
                        <div className="program-grid-img">
                            <img src={gridImg2} alt="Image 2" />
                        </div>
                        <div className="program-overlay">
                            <h3>Senior Elite Program</h3>
                            <p>Bringing the right people together to lead the change.</p>
                        </div>
                    </Link>
                </div>
                <div className="program-grid3">
                    <Link to="/enquiry">
                        <h4>Our Program</h4>
                        <h2>From Grassroots to Glory</h2>
                        <p>We provide a comprehensive program designed to take athletes from the grassroots level to the pinnacle of success, nurturing talent every step of the way.</p>
                    </Link>
                </div>
                <div className="program-grid4">
                    <Link to="/enquiry">
                        <div className="program-grid-img">
                            <img src={gridImg3} alt="Image 3" />
                        </div>
                        <div className="program-overlay">
                            <h3>Skate Academy</h3>
                            <p>Developing skating skills from beginner to advanced levels with personalized coaching and state-of-the-art facilities.</p>
                        </div>
                    </Link>
                </div>
                <div className="program-grid5">
                    <Link to="/enquiry">
                        <div className="program-grid-img">
                            <img src={gridImg4} alt="Image 4" />
                        </div>
                        <div className="program-overlay">
                            <h3>Youth Development</h3>
                            <p>Empowering young athletes to reach their full potential.</p>
                        </div>
                    </Link>
                </div>
            </div>

            {/* About Section */}
            <section className="about-us" id="about">
                <div className="about-container">
                    <div className="about-row">
                        {/* First Column */}
                        <div className="about-col-md-6">
                            <div className="about-text">
                                <h2>About Our Academy</h2>
                                <h3>Empowering Athletes Through<span> Excellence</span></h3>
                                <blockquote className="about-quote">
                                    <p>"We believe in pushing the limits of excellence and inspiring greatness in every athlete."</p>
                                    <footer>- Head Coach</footer>
                                </blockquote>
                                <div className="about-counter">
                                    <div className="about-counter-item">
                                        <span className="about-count"><CountUp end={250} duration={2} /></span>
                                        <p>Trained Athletes</p>
                                    </div>
                                    <div className="about-counter-item">
                                        <span className="about-count"><CountUp end={15} duration={2} /></span>
                                        <p>Years of Excellence</p>
                                    </div>
                                    <div className="about-counter-item">
                                        <span className="about-count"><CountUp end={50} duration={2} /></span>
                                        <p>Championships Won</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Second Column */}
                        <div className="about-col-md-6">
                            <div className="about-image">
                                <img src={aboutImg1} alt="Academy Image" className="about-img1" />
                                <img src={aboutImg2} alt="Secondary Image" className="about-img2" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Facility Section */}
            <section className="facility-section">
                <div className="facility-heading">
                    <h2>Our Skating <span>Facilities</span></h2>
                    <p>Discover our top-notch skating facilities designed.</p>
                </div>
                <div className="facility-grid">
                    <div className="facility-item">
                        <img src={facility1} alt="Skating Rink" />
                        <div className="facility-overlay">
                            <h4>Premier Skating Rink</h4>
                            <p>Enjoy skating on our state-of-the-art rink, perfect for both practice and performances.</p>
                        </div>
                    </div>
                    <div className="facility-item">
                        <img src={facility2} alt="Training Area" />
                        <div className="facility-overlay">
                            <h4>Training Area</h4>
                            <p>Access to specialized training zones tailored to enhance your skills.</p>
                        </div>
                    </div>
                    <div className="facility-item">
                        <img src={facility3} alt="Equipment" />
                        <div className="facility-overlay">
                            <h4>High-Quality Equipment</h4>
                            <p>We provide the best equipment to ensure a top-notch training experience.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Home;
