import React from "react";
import '../CSS/Global.css';
import Header from "./Header";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <>
    <Header />
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-heading">Privacy Policy</h1>
      <p className="privacy-policy-intro">
        Welcome to <strong>Radical Sports India</strong>. We value your privacy and are committed to ensuring your personal information is secure. This policy outlines how we handle your data.
      </p>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-subheading">1. Information We Collect</h2>
        <ul className="privacy-policy-list">
          <li>Full name, email address, and contact number during registration.</li>
          <li>Date of birth and emergency contact for safety purposes.</li>
          <li>Payment details for enrollment in our programs or events.</li>
          <li>Photos or videos taken during our skating events (with consent).</li>
          <li>Device information when accessing our website or app.</li>
          <li>Feedback provided through surveys or reviews.</li>
          <li>IP address and browser type for analytics purposes.</li>
          <li>Attendance and performance data during sessions.</li>
          <li>Medical history relevant to skating activities (voluntary).</li>
          <li>Social media interactions with our official pages or groups.</li>
        </ul>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-subheading">2. How We Use Your Information</h2>
        <ul className="privacy-policy-list">
          <li>To register and enroll participants in our roller-skating programs.</li>
          <li>To manage class schedules, attendance, and performance tracking.</li>
          <li>To improve our website, services, and user experience.</li>
          <li>To provide updates on events, promotions, or important notifications.</li>
          <li>To process payments and maintain financial records securely.</li>
          <li>To create promotional materials with consent (e.g., photos/videos).</li>
          <li>To conduct surveys for feedback on our services.</li>
          <li>To comply with safety regulations and ensure participant wellbeing.</li>
          <li>To respond to customer queries or complaints efficiently.</li>
          <li>To analyze user interactions for future service improvements.</li>
        </ul>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-subheading">3. Data Sharing</h2>
        <ul className="privacy-policy-list">
          <li>We do not sell your personal information to third parties.</li>
          <li>Data may be shared with trusted service providers (e.g., payment processors).</li>
          <li>Photos/videos shared only with your explicit consent for promotional use.</li>
          <li>Shared with law enforcement only if legally required.</li>
          <li>Data may be anonymized for research or analytics purposes.</li>
          <li>Shared with affiliates for improving the quality of our services.</li>
          <li>Third-party tools (e.g., Google Analytics) may access limited data.</li>
          <li>For security, data may be shared during fraud investigations.</li>
          <li>We ensure third-party providers follow strict data protection protocols.</li>
          <li>Collaborators in organizing skating events may access limited data.</li>
        </ul>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-subheading">4. Security Measures</h2>
        <ul className="privacy-policy-list">
          <li>Regularly updating systems to prevent unauthorized access.</li>
          <li>Using encryption for sensitive data during transmission.</li>
          <li>Restricting access to personal information to authorized personnel only.</li>
          <li>Conducting regular audits of our data storage systems.</li>
          <li>Securely deleting unnecessary data to reduce risks.</li>
          <li>Using secure payment gateways for processing transactions.</li>
          <li>Employing firewalls and intrusion detection systems.</li>
          <li>Implementing two-factor authentication for account access.</li>
          <li>Ensuring strong password policies for all internal systems.</li>
          <li>Training employees on data protection best practices.</li>
        </ul>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-subheading">5. Your Rights</h2>
        <ul className="privacy-policy-list">
          <li>Right to access and review the personal information we hold about you.</li>
          <li>Right to request correction of inaccurate or outdated information.</li>
          <li>Right to withdraw consent for data usage at any time.</li>
          <li>Right to request deletion of your personal data (subject to regulations).</li>
          <li>Right to file a complaint regarding data misuse or breaches.</li>
          <li>Right to receive a copy of your data in a commonly used format.</li>
          <li>Right to restrict or object to certain types of data processing.</li>
          <li>Right to unsubscribe from promotional emails or notifications.</li>
          <li>Right to know how long your data will be stored.</li>
          <li>Right to contact us at <a href="mailto:info@radicalsportsindia.com" className="privacy-policy-link">info@radicalsportsindia.com</a> for concerns.</li>
        </ul>
      </section>

    </div>
    <Footer />
    </>
  );
};

export default PrivacyPolicy;
