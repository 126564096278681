import React from "react";
import "../CSS/Global.css";
import Header from "./Header";
import Footer from "./Footer";

const TermsAndConditions = () => {
  return (
    <>
    <Header />
    <div className="terms-container">
      <h1 className="terms-heading">Terms and Conditions</h1>
      <p className="terms-intro">
        Welcome to <strong>Radical Sports India </strong>These terms and conditions outline the rules and regulations for using our services, attending our skating academy, and accessing our website. By registering or participating, you agree to comply with these terms.
      </p>

      <section className="terms-section">
        <h2 className="terms-subheading">1. Enrollment and Registration</h2>
        <ul className="terms-list">
          <li>Participants must complete the registration process before attending classes.</li>
          <li>A valid age-proof and emergency contact details are required during registration.</li>
          <li>All fees must be paid before the commencement of the program.</li>
          <li>Registration is non-transferable and non-refundable once the program begins.</li>
          <li>Participants under 18 must have parental or guardian consent to enroll.</li>
          <li>Late entries may not be accepted for scheduled events or programs.</li>
          <li>Participants agree to adhere to the schedule provided by the academy.</li>
          <li>The academy reserves the right to terminate registration for non-compliance with rules.</li>
          <li>Changes to program schedules will be communicated promptly.</li>
          <li>Re-enrollment is required for new seasons or sessions.</li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheading">2. Code of Conduct</h2>
        <ul className="terms-list">
          <li>All participants must behave respectfully towards instructors and fellow students.</li>
          <li>Use of offensive language or behavior will not be tolerated.</li>
          <li>Proper skating gear must be worn during sessions, including helmets and pads.</li>
          <li>Participants must arrive on time for all scheduled sessions and events.</li>
          <li>Parents or guardians are requested to avoid disrupting class sessions.</li>
          <li>The use of personal electronic devices during class is discouraged.</li>
          <li>Any damage to academy property must be reported immediately.</li>
          <li>Participants must adhere to safety guidelines shared by the academy.</li>
          <li>Violations of the code of conduct may result in suspension or expulsion.</li>
          <li>Radical Sports India reserves the right to refuse service to violators.</li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheading">3. Payment and Refund Policy</h2>
        <ul className="terms-list">
          <li>All program fees must be paid in full at the time of registration.</li>
          <li>Partial payments or installment options may be available upon request.</li>
          <li>Fees are non-refundable, except in cases of program cancellation by the academy.</li>
          <li>Refund requests for personal reasons will not be entertained.</li>
          <li>Additional charges may apply for specialized training or events.</li>
          <li>Late payment may result in a penalty or denied participation in classes.</li>
          <li>Receipts will be issued for all payments made to the academy.</li>
          <li>Payment methods include online transfer, credit/debit cards, or cash.</li>
          <li>Refunds (if applicable) will be processed within 14 business days.</li>
          <li>Any disputes regarding payments must be addressed in writing.</li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheading">4. Liability and Safety</h2>
        <ul className="terms-list">
          <li>Radical Sports India is not liable for injuries sustained during activities.</li>
          <li>Participants are responsible for ensuring they are medically fit to skate.</li>
          <li>Instructors will ensure safety measures but cannot guarantee accident-free sessions.</li>
          <li>Parents or guardians must supervise children under 12 years of age.</li>
          <li>Participants must disclose any medical conditions before joining the program.</li>
          <li>The academy holds no liability for personal belongings lost during sessions.</li>
          <li>All safety instructions provided by instructors must be strictly followed.</li>
          <li>Accidents caused by participant negligence will not hold the academy accountable.</li>
          <li>Participants agree to sign a liability waiver during registration.</li>
          <li>Emergency medical assistance will be provided if necessary, at the participant's expense.</li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheading">5. Intellectual Property</h2>
        <ul className="terms-list">
          <li>All content on our website, including text, images, and videos, is proprietary.</li>
          <li>Reproduction or distribution of our materials without consent is prohibited.</li>
          <li>Logos, trademarks, and branding elements belong to Radical Sports India.</li>
          <li>Participants grant permission for the academy to use event photos or videos for promotional purposes.</li>
          <li>Unauthorized use of academy materials may result in legal action.</li>
          <li>Third-party content used is acknowledged and credited accordingly.</li>
          <li>Feedback or suggestions provided may be used for academy improvement without compensation.</li>
          <li>Content from our website cannot be used for commercial purposes.</li>
          <li>Plagiarism of academy training methods or resources is strictly prohibited.</li>
          <li>Requests for use of content must be made in writing to the academy management.</li>
        </ul>
      </section>
    </div>
    <Footer />
    </>
  );
};

export default TermsAndConditions;
