import { Box, Divider, Flex, Image, Text } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ArrowBackIcon } from "@chakra-ui/icons";

function Orderdesc() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [order, setOrder] = useState({});
    const [orderProd, setOrderProd] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalStrikeOffPrice, setTotalStrikeOffPrice] = useState(0);
    const [addressPro, setAddressPro] = useState({});
    const login = JSON.parse(localStorage.getItem("login")) || "";
    const [deliveryCharge,setDeliveryCharge]=useState()

    const fetchOrderData = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/payment/order/${id}`);
            if (!response.ok) throw new Error("Failed to fetch order data");
            const res = await response.json();

            const cartProducts = JSON.parse(res.data.cart_products || "[]");
            const address = JSON.parse(res.data.address || "{}");

            let totalPrice = 0;
            let totalStrikeOffPrice = 0;
            cartProducts.forEach((product) => {
                totalPrice += product.sale_price   || 0;
                totalStrikeOffPrice += product.regular_price  || 0;
            });

            setOrder(res.data);
            setOrderProd(cartProducts);
            setAddressPro(address);
            setTotalPrice(totalPrice);
            setTotalStrikeOffPrice(totalStrikeOffPrice);
        } catch (error) {
            console.error("Error fetching order data:", error);
        }
    };
    console.log(orderProd);

    const logout = () => {
        localStorage.removeItem("login");
        window.location.reload();
    };

    const fetchDelivery = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/delivery`)
            const res = await response.json();
            // console.log(res.data);
            // setDeliveryCharge(res.data)
            setDeliveryCharge(res.data.delivery_price)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchOrderData();
        fetchDelivery();
    }, []);

    return (
        <Box position={"relative"} padding={{ base: "10px", lg: "30px" }}>
            <Box
                boxShadow={"0 0 8px 0 rgba(0,0,0,0.1)"}
                w={{ base: "100%", lg: "70%" }}
                m="auto"
                mb="20px"
                borderRadius="10px"
                overflow="hidden"
            >
                <Flex>
                    {/* Sidebar */}
                    

                    {/* Order Details */}
                    <Box w={{ base: "100%", lg: "75%" }} p="20px">
                        <Box mb="20px" cursor="pointer" onClick={() => navigate("/orders")}>
                            <ArrowBackIcon w="6" h="6" />
                        </Box>
                        <Box mb="20px">
                            <Text fontWeight="bold" fontSize="19px">
                                Order Summary
                            </Text>
                            <Text color="#696969" fontSize="13px">
                                Arrived at{" "}
                                {new Date(order.updatedAt).toLocaleString("en-IN", {
                                    timeZone: "Asia/Kolkata",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                })}
                            </Text>
                        </Box>
                        <Box>
                            <Text fontWeight="bold" fontSize="15px">
                                {orderProd.length} items in this order
                            </Text>
                            {orderProd.map((item, idx) => (
                                <Flex
                                    key={idx}
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mb="10px"
                                >
                                    <Flex alignItems="center" gap="20px">
                                        <Image
                                            borderRadius="10px"
                                            w="80px"
                                            h="80px"
                                            border="1px solid #e3e3e3"
                                            src={`https://100billionoms.top/${item.product_image}`}
                                            alt={item.equipment_name}
                                        />
                                        <Box>
                                            <Text fontWeight="500" fontSize="14px">
                                                {item.equipment_name}
                                            </Text>
                                            <Text fontSize="12px" color="#696969">
                                                {item.quantity}
                                            </Text>
                                        </Box>
                                    </Flex>
                                    <Text fontWeight="bold" fontSize="14px">
                                        ₹{item.sale_price}
                                    </Text>
                                </Flex>
                            ))}
                        </Box>
                        <Divider my="20px" />
                        <Box>
                            <Text fontWeight="bold" fontSize="15px">
                                Bill Details
                            </Text>
                            <Box mt="10px">
                                <Flex justifyContent="space-between" mb="5px">
                                    <Text fontSize="14px">MRP</Text>
                                    <Text fontSize="14px">₹{totalStrikeOffPrice}</Text>
                                </Flex>
                                <Flex justifyContent="space-between" mb="5px">
                                    <Text fontSize="14px" color="#256FEF">
                                        Product Discount
                                    </Text>
                                    <Text fontSize="14px" color="#256FEF">
                                        ₹{totalStrikeOffPrice - totalPrice}
                                    </Text>
                                </Flex>
                                <Flex justifyContent="space-between" mb="5px">
                                    <Text fontSize="14px">Item Total</Text>
                                    <Text fontSize="14px">₹{totalPrice}</Text>
                                </Flex>
                                {/* <Flex justifyContent="space-between" mb="5px">
                                    <Text fontSize="14px">Handling Charge</Text>
                                    <Text fontSize="14px">+₹2</Text>
                                </Flex> */}
                                <Flex justifyContent="space-between" mb="5px">
                                    <Text fontSize="14px">Delivery Charges</Text>
                                    <Text fontSize="14px">
                                        {deliveryCharge}
                                    </Text>
                                </Flex>
                                <Flex justifyContent="space-between" fontWeight="bold">
                                    <Text fontSize="15px">Bill Total</Text>
                                    <Text fontSize="15px">₹{(order.amount || 0) / 100}</Text>
                                </Flex>
                            </Box>
                        </Box>
                        <Divider my="20px" />
                        <Box>
                            <Text fontWeight="bold" fontSize="15px">
                                Order Details
                            </Text>
                            <Box mt="10px">
                                <Text fontSize="12px" color="#696969">
                                    Order ID
                                </Text>
                                <Text fontSize="14px">{order.order_id}</Text>
                                <Text fontSize="12px" color="#696969" mt="10px">
                                    Payment
                                </Text>
                                <Text fontSize="14px">
                                    {order.payment_mode === "Pay-on-delivery"
                                        ? "Paid on delivery"
                                        : "Paid online"}
                                </Text>
                                <Text fontSize="12px" color="#696969" mt="10px">
                                    Deliver To
                                </Text>
                                <Text fontSize="14px">
                                    {addressPro.name}, {addressPro.area}
                                </Text>
                                <Text fontSize="12px" color="#696969" mt="10px">
                                    Order Placed
                                </Text>
                                <Text fontSize="14px">
                                    {new Date(order.updatedAt).toLocaleString("en-IN", {
                                        timeZone: "Asia/Kolkata",
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        second: "numeric",
                                        hour12: false,
                                    })}
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default Orderdesc;
