

import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import SearchAddress from "./SearchAddress";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";

import locationpin from "../IMG/locationpin.png"
import aanounce from "../IMG/announcement.png"


const Map = ({ selectedLocation, openModal1, address1, setSelectedLocation, onQueryChange, query, setAddress1 }) => {
    let address = JSON.parse(localStorage.getItem("address"));
    const { isLoaded } = useLoadScript({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU",
        libraries: ["places"]
    });
    const mapRef = React.useRef();
    const [isInputFocused, setInputFocused] = useState(false);
    let [showavailable, setShowAvailable] = useState()
    console.log(selectedLocation)
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
        console.log('Map loaded:', map);
    }, []);
    const [addressmain, setAddressmain] = useState({ building: "", rest: "" });

    useEffect(() => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${selectedLocation.lat},${selectedLocation.lng}&sensor=false&key=AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU`;
        fetch(url)
            .then((res) => res.json())
            .then((res) => {
                console.log(res);
                const formattedAddress = res.results[0].formatted_address;
                const addressParts = formattedAddress.split(", ");
                setAddressmain({
                    building: addressParts[0], // First part as building name
                    rest: addressParts.slice(1).join(", ") // Rest of the address
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const [addressMain, setAddressMain] = useState({ building: "", rest: "" });

    useEffect(() => {


        if (!isInputFocused && query !== "") {
            // Set a timeout for 500 milliseconds before updating the address parts

            const addressParts = query.split(", ");
            setAddressMain({
                building: addressParts[0], // First part as building name
                rest: addressParts.slice(1).join(", ") // Rest of the address
            });

        }

        // Cleanup function to clear the timeout on component unmount

    }, [query, onQueryChange]);

    console.log(query)


    let handleDetect = () => {
        if (!navigator.geolocation) {
            // Geolocation is not supported by the browser
            console.log("Geolocation is not supported by your browser.");
            return;
        }

        // Try to get the current position

        navigator.geolocation.getCurrentPosition(
            (position) => {
                console.log(position.coords);
                setSelectedLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })

                const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&sensor=false&key=AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU`;
                fetch(url)
                    .then((res) => res.json())
                    .then((res) => {
                        console.log(res);
                        setAddress1(res.results[0].formatted_address)
                        onQueryChange(res.results[0].formatted_address)

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            },
            () => {
                // If the user denied geolocation access
                console.log("Please allow geolocation access to detect your location.");

            }
        );
    };
    let fetchPincode = async () => {
        try {
            let response = await fetch(`https://100billionoms.top/pincode`);
            let res = await response.json();
            console.log(res.data);

            // Extract the pincode from the address1 string using a regular expression
            let pincodeFromAddress1 = address1.match(/\b\d{6}\b/)[0];
            console.log(pincodeFromAddress1);
            // Check if the pincode from address1 exists in the fetched data
            let isPincodeMatched = res.data.some(item => item.pincode === pincodeFromAddress1);
            console.log(isPincodeMatched)
            setShowAvailable(isPincodeMatched);
            if (!isPincodeMatched) {
                console.log("Unauthenticated: Delivery not available.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    console.log(showavailable)

    useEffect(() => {
        fetchPincode();
    }, [query, onQueryChange]);

    if (!isLoaded) return "Maps";
    return (
        <>
            <Box style={{ position: "relative", width: "100%", height: "80vh" }}>
                <GoogleMap
                    mapContainerStyle={{
                        width: "100%",
                        height: "100%",
                    }}
                    center={selectedLocation}
                    zoom={20}
                    options={{
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                    }}
                    onLoad={onMapLoad}
                >
                    <Box w={"95%"} position="absolute" top="10px" left="50%" transform="translateX(-50%)">
                        <SearchAddress address1={address1} setSelectedLocation={setSelectedLocation} query={query}
                            onFocus={() => setInputFocused(true)}
                            onBlur={() => setInputFocused(false)} // Track focus state
                            onQueryChange={onQueryChange} />
                    </Box>
                    <Marker
                        position={selectedLocation}
                        icon={"http://maps.google.com/mapfiles/ms/icons/red-dot.png"}

                    >

                    </Marker>
                    <Box style={{ position: "absolute", bottom: "20px", left: "20px" }}>
                        <Button border={"1px solid green"} color={"green"} size={"sm"} onClick={handleDetect}>
                            <i class="fa-solid fa-location-crosshairs" style={{ marginRight: "10px" }}></i>Go to Current Position</Button>
                    </Box>
                </GoogleMap>

            </Box>
            {
                showavailable ? <Box bg={"white"} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>
                    <Box p={"12px 12px 24px"}>
                        <Text fontSize={"15px"} fontWeight={"bold"}>Delivering your order to</Text>
                        <Box p={"8px 12px 8px 8px"} mt={"20px"} border={"0.5px solid rgb(232, 232, 232)"} borderRadius={"12px"} bg={"rgb(246, 252, 252);"}>
                            <Flex gap={"10px"}>
                                <Image w={"10%"} src={locationpin} />
                                <Box>
                                    <Text fontWeight={"bold"}>{query === "" ? addressmain.building : addressMain.building}</Text>
                                    <Text>{query === "" ? addressmain.rest : addressMain.rest}</Text>
                                </Box>
                            </Flex>

                        </Box>

                    </Box>

                </Box> :
                    <Box p={"12px 12px 24px"} borderTopRadius={"40px"} borderTopLeftRadius={"40px"} bg={"white"}  width={"100%"} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }} mb={"5px"}>
                        <Box width={"100%"}>
                            <Box>
                                <Image m={"auto"} w={"20%"} src={aanounce} />
                            </Box>
                            <Box>
                                <Text fontWeight={"bold"} fontSize={"20px"} textAlign={"center"}>Oops!</Text>
                                <Text textAlign={"center"} fontWeight={"500"} color={"rgb(105, 105, 105)"} pr={"12px"} pl={"12px"} fontSize={"14px"}>
                                    Delivery is not available at this location at the moment. Please select a different location.
                                </Text>
                            </Box>
                        </Box>
                    </Box>
            }
            {console.log(showavailable)}
            {showavailable ? (
                <Box p={"12px 12px 24px"} borderTopRadius={"40px"} borderTopLeftRadius={"40px"} bg={"white"} width={"100%"} display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }} mb={"5px"}>
                    <Box width={"100%"}>
                        <Text fontSize={"15px"} fontWeight={"bold"}>Delivering your order to</Text>
                        <Box p={"8px 12px 8px 8px"} mt={"10px"} border={"0.5px solid rgb(232, 232, 232)"} borderRadius={"12px"} bg={"rgb(246, 252, 252)"}>
                            <Flex gap={"10px"}>
                                <Image src={locationpin} />
                                <Box>
                                    <Text fontWeight={"bold"} fontSize={"13px"}>
                                        {query === "" ? addressmain.building : addressMain.building}
                                    </Text>
                                    <Text color={"#828282"} fontSize={"12px"}>
                                        {query === "" ? addressmain.rest.substring(0, 50) : addressMain.rest.substring(0, 50)}
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                    <Box p={"12px 0px 14px"} mt={"10px"} bg={"rgb(49, 134, 22)"} borderRadius={"8px"} color={"rgb(255, 255, 255)"} textAlign={"center"} fontSize={"17px"} fontWeight={"500"} cursor={"pointer"}>
                        <button onClick={openModal1}>Confirm location & proceed</button>
                    </Box>
                </Box>
            ) : (
                <Box p={"12px 12px 24px"} borderTopRadius={"40px"} borderTopLeftRadius={"40px"}  bg={"white"} width={"100%"} display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }} mb={"5px"}>
                    <Box width={"100%"}>
                        <Box>
                            <Image m={"auto"} w={"40%"} src={aanounce} />
                        </Box>
                        <Box>
                            <Text fontWeight={"bold"} fontSize={"20px"} textAlign={"center"}>Oops!</Text>
                            <Text textAlign={"center"} fontWeight={"500"} color={"rgb(105, 105, 105)"} pr={"12px"} pl={"12px"} fontSize={"14px"}>
                                Delivery is not available at this location at the moment. Please select a different location.
                            </Text>
                        </Box>
                    </Box>
                </Box>
            )}

        </>
    );
};

export default Map;