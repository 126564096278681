import React, { useState } from 'react';
import '../CSS/Global.css'; // Import the updated CSS file
import Header from './Header';
import Footer from './Footer';

const InquiryForm = () => {
  // State to manage form data
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    parentName: '',
    school: '',
    dob: '',
    age: '',
    remark: '', // Removed skatedCategory from state
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data submitted:', formData);
    // You can add form submission logic here
  };

  return (
   <>
   <Header />
    <div className="inquiry-form-container">
      <h2>Enquiry Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="inquiry-form-step">
          <div className="inquiry-input-group">
            <label htmlFor="name">Name (Student)</label>
            <div className="inquiry-input-wrapper">
              <i className="fa fa-user inquiry-input-icon"></i>
              <input
                id="name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter Name"
                required
              />
            </div>
          </div>

          <div className="inquiry-input-group">
            <label htmlFor="mobile">Mobile Number</label>
            <div className="inquiry-input-wrapper">
              <i className="fa fa-phone inquiry-input-icon"></i>
              <input
                id="mobile"
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                placeholder="Enter Mobile Number"
                required
              />
            </div>
          </div>

          <div className="inquiry-input-group">
            <label htmlFor="parentName">Parent Name</label>
            <div className="inquiry-input-wrapper">
              <i className="fa fa-user inquiry-input-icon"></i>
              <input
                id="parentName"
                type="text"
                name="parentName"
                value={formData.parentName}
                onChange={handleChange}
                placeholder="Enter Parent Name"
                required
              />
            </div>
          </div>

          <div className="inquiry-input-group">
            <label htmlFor="school">School</label>
            <div className="inquiry-input-wrapper">
              <i className="fa fa-school inquiry-input-icon"></i>
              <input
                id="school"
                type="text"
                name="school"
                value={formData.school}
                onChange={handleChange}
                placeholder="Enter School Name"
                required
              />
            </div>
          </div>

          <div className="inquiry-input-group">
            <label htmlFor="dob">Date of Birth</label>
            <div className="inquiry-input-wrapper">
              <i className="fa fa-calendar inquiry-input-icon"></i>
              <input
                id="dob"
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="inquiry-input-group">
            <label htmlFor="age">Age</label>
            <div className="inquiry-input-wrapper">
              <i className="fa fa-cake-candles inquiry-input-icon"></i>
              <input
                id="age"
                type="number"
                name="age"
                value={formData.age}
                onChange={handleChange}
                placeholder="Enter Age"
                required
              />
            </div>
          </div>
        </div>

        <div className="inquiry-input-group remark-inquiry">
            <label htmlFor="remark">Remark</label>
            <div className="inquiry-input-wrapper">
              <i className="fa fa-comments inquiry-input-icon textarea"></i>
              <textarea
                id="remark"
                name="remark"
                value={formData.remark}
                onChange={handleChange}
                placeholder="Enter Remark"
                rows="4"
                className="inquiry-form-textarea"
              ></textarea>
            </div>
          </div>

        <button type="submit" className="inquiry-submit-btn">Submit</button>
      </form>
    </div>
    <Footer />
   </>
  );
};

export default InquiryForm;
