import { Box, Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import CartIcon from "./CartIcon";

function Products() {
    let [data, setData] = useState([]);
    let toast = useToast();
   


    let fetchData = async () => {
        try {
            let response = await fetch(`https://100billionoms.top/equipment/allproducts`)
            let res = await response.json();
            console.log(res);
            // setData(Apple);
            setData(res);

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    let q = localStorage.getItem("quantity")
    console.log(q)
    const [cartItems, setCartItems] = useState(() => {
        const storedCart = localStorage.getItem('cart');
        return storedCart ? JSON.parse(storedCart) : [];
    });

    const handleAdd = (product) => {
        console.log(cartItems);
        const existingItem = cartItems.find((item) => item.id === product.id);

        if (existingItem) {
            // Product already exists in cart

            toast({
                title: `Product already in cart!`,
                status: "warning",
                isClosable: true,
                position: "top"
            })
        } else {
            // Product not in cart, add it with quantity 1
            const updatedProduct = { ...product, quantity: 1 }; // Initialize quantity to 1
            const updatedCartItems = [...cartItems, updatedProduct];
            setCartItems(updatedCartItems);
            localStorage.setItem('cart', JSON.stringify(updatedCartItems));

            toast({
                title: `Product added to cart!`,
                status: "success",
                isClosable: true,
                position: "top"
            })
        }
    };

 
    return (
        <>
            <Header />
            <Box mt={"60px"} p={"10px"}
            // bg={"rgb(243,245,250)"}
            >
                <Box display={"grid"} gridTemplateColumns={{ base: "repeat(2,1fr)", sm: "repeat(2,1fr)", md: "repeat(2,1fr)", lg: "repeat(5,1fr)", xl: "repeat(6,1fr)" }} gap={"10px"} height="calc(-30px + 50vh)">
                    {
                        data.length > 0 ? data.map((ele, idx) => {
                            const percentageOff = ele.regular_price && ele.sale_price
                                ? Math.round(((ele.regular_price - ele.sale_price) / ele.regular_price) * 100)
                                : null;
                            return (

                                <Box mt={"10px"} minWidth={"100%"} borderRadius={"10px"}
                                    key={idx}

                                    border={"2px solid #fafafa"}
                                    bg={"white"}
                                    boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;"
                                    style={{
                                        filter: ele.available === "outofstock" ? 'blur(0.5px)' : 'none', // Apply blur if not available
                                        position: "relative",
                                        display: 'flex',
                                        flexDirection: 'column', // Ensure price and button are at the bottom
                                        justifyContent: 'space-between', // Stretch content
                                        height: '100%', // Stretch box to full height
                                    }}
                                >
                                    {percentageOff && (
                                        <Box
                                            position="absolute"
                                            top="5px"
                                            right="5px"
                                            bg="#1e90ff"
                                            color="white"
                                            px="8px"
                                            py="3px"
                                            borderRadius="5px"
                                            fontSize="12px"
                                            fontWeight="bold"
                                            zIndex="2"
                                        >
                                            {percentageOff}% OFF
                                        </Box>
                                    )}
                                    <Link
                                    // to={`/product/${ele.product_id}`}
                                    >
                                        {ele.available === "outofstock" && (
                                            <Flex
                                                position="absolute"
                                                top="25%"
                                                left="50%"
                                                transform="translate(-50%, -50%)" // Center the button
                                                zIndex="1"
                                            >
                                                <Button size="sm" bg="black" color={"white"}>
                                                    OUT OF STOCK
                                                </Button>
                                            </Flex>
                                        )}
                                        <Image
                                            borderTopLeftRadius={"10px"}
                                            borderTopRightRadius={"10px"}
                                            w={"100%"}
                                            p={"20px"}
                                            // h={"100%"}
                                            // h={{ base: "40%", sm: "40%", md: "40%", lg: "45%", xl: "45%" }}
                                            alt="sliderImage"
                                            key={ele?.id}
                                            // src={`https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/products/sliding_image/391306a.jpg?ts=1690814339`}
                                            src={`https://100billionoms.top${(ele.product_image)}`}

                                        />

                                    </Link>
                                    <Box p={"10px"}>
                                        {/* <Button size={"xs"}> 8mins</Button> */}
                                        <Text
                                            mt={"3px"}
                                            fontWeight={"600"}
                                            fontSize={"14px"}
                                            style={{
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 1,
                                                overflow: 'hidden',
                                                height: '25px',
                                                whiteSpace: 'normal',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {ele.equipment_name}
                                        </Text>
                                        {/* <Text fontSize={"12px"}>{ele.unit}</Text> */}
                                        <Box mt={"5px"} >
                                            <Flex justifyContent={"space-between"} alignItems={"flex-end"}>
                                                <Box>
                                                    <Text fontWeight={"bold"} fontSize={"13px"}>₹{ele.sale_price}</Text>
                                                    {
                                                        ele.strike_off === null ? null : <Text textDecoration={"line-through"} fontSize={"13px"} color={"rgb(151,144,139)"}>₹{ele.regular_price}</Text>
                                                    }
                                                </Box>
                                                <Box>


                                                    {
                                                        ele.stock_avornot === "Available" ? <Button
                                                            onClick={() => handleAdd(ele, idx)}
                                                            size={"sm"} color={"#1e90ff"} border={"1px solid #1e90ff"}>ADD</Button> :
                                                            null
                                                    }

                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Box>

                                </Box>


                            );
                        })
                            :
                            <Box mt={"30px"} w={"100%"}>
                                <Text fontSize={"lg"}>  currently not available...</Text>
                            </Box>
                    }
                </Box>
            </Box>

            <CartIcon cartItems={cartItems}/>
        </>
    )
}

export default Products;