import React from 'react';
import '../CSS/Global.css'; // Custom CSS for styling
import Header from './Header';
import Footer from './Footer';

const ContactUs = () => {
    return (
       <>
       <Header/>
       <section className="contact-contact-us">
            <div className="contact-contact-header">
                <h2>Get In Touch</h2>
            </div>
            <div className="contact-contact-content">
                <div className="contact-contact-form">
                    <h3>Send us a Message</h3>
                    <form>
                        <div className="contact-form-group">
                            <label><i className="fa-solid fa-user"></i> Name</label>
                            <input type="text" placeholder="Enter your name" />
                        </div>
                        <div className="contact-form-group">
                            <label><i className="fa-solid fa-envelope"></i> Email</label>
                            <input type="email" placeholder="Enter your email" />
                        </div>
                        <div className="contact-form-group">
                            <label><i className="fa-solid fa-message"></i> Message</label>
                            <textarea rows="4" placeholder="Your message"></textarea>
                        </div>
                        <button type="submit" className="contact-submit-btn">
                            <i className="fa-solid fa-paper-plane"></i> Send Message
                        </button>
                    </form>
                </div>

                <div className="contact-contact-info">
                    <h3>Contact Information</h3>
                    <p><i className="fa-solid fa-building"></i> Malad East Mumbai India 400097</p>
                    <p><i className="fa-solid fa-phone"></i> +919820163394</p>
                    <p><i className="fa-solid fa-envelope"></i> Info@radicalsportsindia.com</p>
                    <div className="contact-social-icons">
                        <i className="fa-brands fa-twitter"></i>
                        <i className="fa-brands fa-linkedin"></i>
                        <i className="fa-brands fa-instagram"></i>
                    </div>
                </div>
            </div>
            <div className="contact-map-container">
                <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.008701731468!2d-122.40046238468197!3d37.78830057975811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085809d97e1c6c7%3A0x4b16c59b1d4d3d56!2sSalesforce%20Tower!5e0!3m2!1sen!2sus!4v1625142603066!5m2!1sen!2sus"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>
        </section>
       <Footer/>
       </>
    );
};

export default ContactUs;
