// ForgotPassword.js
import React from 'react';
import "../CSS/Global.css";
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  let navigate = useNavigate()
  return (
    <div className='form'>
      <div className="forgot-container">
        <div className="forgot-left login-bg">
          <h1>Master Skating with <span>Radical Sports Academy</span></h1>
        </div>

        <div className="forgot-right">
          <h2>Forgot Password</h2>
          <p>Enter your email to reset your password</p>
          <form className="forgot-form">
            <div className="forgot-input-container">
              <i className="fas fa-envelope forgot-left-icon"></i>
              <input type="email" placeholder="Email" required />
            </div>
            <button className="forgot-btn" type="submit">
              Submit
            </button>
          </form>
          <div className="forgot-forgot-signup">
            <p>Remember your password? <button onClick={() => navigate('/login')}>Login</button></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
