import React, { useEffect, useState } from 'react';
import '../CSS/Global.css';
import Price from './Price';
import Header from './Header'
import Footer from './Footer'
import axios from "axios"
import { Box, Button, Text } from "@chakra-ui/react"
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const MultiStepForm = () => {
    const [step, setStep] = useState(1);
    let login = JSON.parse(localStorage.getItem("login")) || "";
    // const [toast, setToast] = useState(null);

    const showToast = (message, type) => {
        // setToast({ message, type });
        // setTimeout(() => setToast(null), 3000);
    };


    const [addressR, setAddress] = useState({
        address: '',
        street: '',
        pincode: '',
        state: '',
        country: ''
    })
    const [formData, setFormData] = useState({
        // Personal Details
        full_name: login.full_name,
        Students_id: login.emplyee_ID,
        phone: '',
        email: login.email,
        blood_group: '',
        dob: '',
        nationality: '',
        gender: '',
        age: '',
        standard: '',
        photo: '',
        school_name: '',
        father_name: '',
        father_occupation: '',
        mother_name: '',
        mother_occupation: '',
        mother_phone: '',
        father_phone: "",
        // center_name: "",
        residential_address: "",
        // Address Details
        // address: '',
        // street: '',
        // pincode: '',
        // state: '',
        // country: '',

        // Program Details
        subject: '',
        class_level: 'Beginner',
        session: 'Daily',
        center_name: '',
        timing: [],
        // Payment Option
        paymentOption: 'Razorpay',

        // Fee Structure
        pricing_plan: [],
        feeType: ''
    });
    const [center, setCenter] = useState([]);
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [price, setPrice] = useState()
    const [admission, setAddmission] = useState({})
    const [paymentData, setPaymentData] = useState([]);
    const navigate = useNavigate()
    const [classTimings, setClassTimings] = useState([]);
    const [filteredTimings, setFilteredTimings] = useState([]);
    const [selectedTimings, setSelectedTimings] = useState({});


    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the selected file
        setFormData({
            ...formData,
            photo: file // Store the file in the state (assuming center is your state object)
        });
    };
    const handlePlanSelect = (plan, price) => {
        setSelectedPlan(plan);
        setPrice(price)
        console.log(price)
        setFormData({
            ...formData,
            pricing_plan: plan
        });
        // Do something with the selected plan if needed
        console.log('Selected Plan in Parent:', plan);
    };

    const fetchDataAdmission = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/admission/singlead/${login.emplyee_ID}`)
            const res = await response.json();
            console.log(res);
            setAddmission(res);
        } catch (error) {
            console.log(error);
        }
    }
    const fetchCenterList = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/centerlist/admin1997`)
            const res = await response.json();
            console.log(res);
            setCenter(res);
        } catch (error) {
            console.log(error);
        }
    }

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
        if (event.target.name === 'center_name') {
            const selected = center.find(c => c.center_name === event.target.value);
            setSelectedCenter(selected); // Update selected center details
        }
    };

    const fetchClassTiming = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/classschedule/getalldata`);
            const res = await response.json();

            const filteredData = res.filter((classData) => {
                const classLevels = JSON.parse(classData.class_level || "[]");
                const ageGroups = JSON.parse(classData.age_group || "[]");

                return (
                    classData.class_name === formData.center_name &&
                    classLevels.includes(formData.class_level) &&
                    ageGroups.some((ageRange) => {
                        const [min, max] = ageRange.split(" - ").map(Number);
                        return +formData.age >= min && +formData.age <= max;
                    })
                );
            });

            setFilteredTimings(filteredData);
        } catch (error) {
            console.error("Error fetching class timings:", error);
        }
    };

    const groupTimingsByDay = (timings) => {
        const grouped = {};
        timings.forEach((timing) => {
            const frequency = JSON.parse(timing.frequency);
            frequency.forEach((slot) => {
                if (!grouped[slot.day]) {
                    grouped[slot.day] = [];
                }
                grouped[slot.day].push({
                    ...slot,
                    center: timing.class_name,
                });
            });
        });
        return grouped;
    };

    const handleSlotSelection = (day, slot) => {
        const selectedSlot = {
            day: slot.day,
            startTime: slot.startTime,
            endTime: slot.endTime,
            center: slot.center,
        };
    
        // Update selected timings state for display
        setSelectedTimings((prev) => ({
            ...prev,
            [day]: selectedSlot, // Ensure only one slot per day
        }));
    
        // Update the formData with the selected timing
        setFormData((prevData) => {
            const updatedTimings = prevData.timing.filter((t) => t.day !== day); // Remove existing timing for the same day
            return {
                ...prevData,
                timing: [...updatedTimings, selectedSlot], // Add the new timing
            };
        });
    };
    

    useEffect(() => {
        if (formData.center_name && formData.class_level && formData.age) {
            fetchClassTiming();
        }
    }, [formData.center_name, formData.class_level, formData.age]);

    const renderTimings = () => {
        const groupedTimings = groupTimingsByDay(filteredTimings);

        return Object.entries(groupedTimings).map(([day, slots]) => (
            <div key={day} className="timing-day-container">
                <h4 className="timing-day-title">{day}</h4>
                <div className="day-slots-container">
                    {slots.map((slot, index) => (
                        <div
                            key={index}
                            className={`timing-card ${selectedTimings[day]?.startTime === slot.startTime ? "selected" : ""
                                }`}
                        >
                            <input
                                type="radio"
                                id={`slot-${day}-${index}`}
                                name={`slot-${day}`}
                                value={`${slot.center}-${slot.day}-${slot.startTime}-${slot.endTime}`}
                                onChange={() => handleSlotSelection(day, slot)}
                                checked={
                                    selectedTimings[day] &&
                                    selectedTimings[day].center === slot.center &&
                                    selectedTimings[day].day === slot.day &&
                                    selectedTimings[day].startTime === slot.startTime &&
                                    selectedTimings[day].endTime === slot.endTime
                                }
                            />
                            <label htmlFor={`slot-${day}-${index}`} className="timing-card-label">
                                <div className="timing-card-content">
                                    <div className="timing-card-time">
                                        {slot.startTime} - {slot.endTime}
                                    </div>
                                </div>
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        ));
    };



    const activeClass = center.filter(center_name => center_name.status === 'Active');

    const options2 = activeClass.map(language => ({
        value: language.center_name,
        label: (`${language.center_name}`),
    }));
    const validateStep1 = () => {
        const requiredFields = [
            'full_name',
            'phone',
            'email',
            'dob',
            'nationality',
            'gender',
            'age',
            'standard',
            'school_name',
            'father_name',
            'father_occupation',
            'mother_name',
            'father_phone'
        ];

        for (let field of requiredFields) {
            if (!formData[field]) {
                toast.error(`Please fill all fields`)
                showToast(`Please fill all  fields`, "error");
                return false;
            }
        }
        return true;
    };

    const validateStep2 = () => {
        const requiredFields = [
            'address',
            'street',
            'pincode',
            'state',
            'country',

        ];

        for (let field of requiredFields) {
            if (!addressR[field]) {
                showToast(`Please fill all  fields`, "error");
                toast.error(`Please fill all fields`)
                return false;
            }
        }
        return true;
    };
    const validateStep3 = () => {
        const requiredFields = [
            'center_name',
            'class_level',


        ];

        for (let field of requiredFields) {
            if (!formData[field]) {
                toast.error(`Please fill all fields`)
                showToast(`Please fill all  fields`);
                return false;
            }
        }
        return true;
    };

    const validateStep4 = () => {
        if (formData && formData.pricing_plan.length === 0) {
            // alert('Please select a pricing plan.');
            toast.error(`Please select a pricing plan`)
            showToast(`Please select a pricing plan`, "error");
            return false;
        }

        return true;
    }

    const nextStep = () => {
        if (step === 1 && !validateStep1()) {
            return;
        }
        if (step === 2 && !validateStep2()) {
            return;
        }
        if (step === 3 && !validateStep3()) {
            return;
        }


        setStep(step + 1);
    };

    const prevStep = () => setStep(step - 1);




    const handleChange = (event) => {
        const { name, value } = event.target;
        setAddress({
            ...addressR,
            [name]: value
        });

        const newAddress = {
            ...addressR,
            [name]: value
        };

        const residentialAddress = [
            newAddress.address,
            newAddress.street,
            newAddress.pincode,
            newAddress.state,
            newAddress.country
        ].filter(Boolean).join(', ');

        setFormData({
            ...formData,
            residential_address: residentialAddress
        });
    };

    console.log(formData)
    console.log(addressR)
    console.log(formData.pricing_plan.duration)
    const sendAddmissionForm = async () => {
        console.log("hii")
        if (!validateStep4()) {
            showToast(`Please select a pricing plan`, "error");
        }

        const formDataToSubmit = new FormData();

        Object.keys(formData).forEach(key => {
            if (key === 'pricing_plan' || key === "timing") {
                // Special handling for cast_crew to ensure each entry is correctly formatted with images

                formDataToSubmit.append(key, JSON.stringify(formData[key]));

            } else if (key === 'photo') {
                formDataToSubmit.append(key, formData[key]); // Append file directly
            } else {
                formDataToSubmit.append(key, formData[key]);
            }
        });
        try {
            const response = await fetch(`https://100billionoms.top/admission`, {
                method: "POST",
                // headers: {
                //     "Content-Type": "application/json"
                // },
                body: (formDataToSubmit)
            })
            if (response.ok) {
                let res = await response.json();
                await handleClick()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script);
        })
    };

    console.log(price);


    const handleClick = async () => {
        try {
            let orderId = "11" + Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

            // Load the Razorpay SDK
            let res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

            if (!res) {
                alert("Razorpay SDK Failed to load. Are you online?");
                return;
            }

            // Define payment details
            let paymentDetails = {
                transaction_id: orderId,
                pricing: formData.pricing_plan,
                duration: formData.pricing_plan.duration,
                user_id: login.emplyee_ID,
                user_phone: login.full_name,
                student: formData,
                amount: price * 100,
                currency: "INR",
                payment_mode: "online",
            };

            // Create order options
            let options = {
                key: "rzp_test_zEWr4dOpfx4w2F",
                currency: paymentDetails.currency,
                amount: paymentDetails.amount,
                name: "Demo",
                description: "Test Transaction",
                handler: async function (response) {

                    if (response.razorpay_payment_id) {
                        // Payment was successful
                        paymentDetails.razorpay_payment_id = response.razorpay_payment_id;
                        paymentDetails.status = 'Paid';
                        let result = await axios.post(`https://100billionoms.top/feespay`, paymentDetails);

                        if (!result.data.isError) {
                            // Redirect to the success page
                            //   checkPaymentStatus();

                            window.location.href = `/`; // Replace "/success-page" with the URL of your success page
                        } else {
                            alert("Server error. Are You Online");
                            window.location.href = "/"; // Redirect to an error page
                        }
                    } else {
                        // Handle payment failure or cancellation

                        paymentDetails.status = 'Failed';
                        await axios.post(`https://100billionoms.top/feespay`, paymentDetails);
                        window.location.href = "/"; // Redirect to an error page
                    }
                },
                prefill: {
                    email: "pankajsbisht143@gmail.com",
                    contact: "9021733853"
                },
                notes: {
                    address: "Razorpay Corporate Office"
                },
                theme: {
                    color: "#1f5215"
                }
            };

            // Initialize Razorpay payment object and open checkout
            let paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', function (response) {

                paymentDetails.status = 'Failed';
                axios.post(`https://100billionoms.top/feespay`, paymentDetails)
                    .then(() => {
                        window.location.href = "/"; // Redirect to an error page
                    })
                    .catch(error => {

                        window.location.href = "/"; // Redirect to an error page
                    });
            });

            paymentObject.open();

        } catch (error) {
            console.log(error);
        }
    };

    const fetchPaymentData = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/feespay/${login.emplyee_ID}`)
            const res = await response.json();
            console.log(res.data);
            setPaymentData(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCenterList();
        fetchDataAdmission();
        fetchPaymentData();
        fetchClassTiming();
    }, [])



    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <div className="form-step">
                        <h2>Admission Form</h2>
                        <div className="input-group">
                            <label>Full Name</label>
                            <i className="fas fa-user input-icon"></i>
                            <input type="text" name="full_name" value={formData.full_name} placeholder="Full Name" onChange={handleInputChange} required />
                        </div>
                        <div className="input-group">
                            <label>Phone Number</label>
                            <i className="fas fa-phone input-icon"></i>
                            <input type="phone" name="phone" value={formData.phone} placeholder="Number" onChange={handleInputChange} required />
                        </div>
                        <div className="input-group">
                            <label>Email</label>
                            <i className="fas fa-envelope input-icon"></i>
                            <input type="email" name="email" value={formData.email} placeholder="Email" onChange={handleInputChange} required />
                        </div>
                        <div className="input-group">
                            <label>Date of Birth</label>
                            <i className="fas fa-calendar input-icon"></i>
                            <input type="date" name="dob" value={formData.dob} placeholder="Date of Birth" onChange={handleInputChange} required />
                        </div>
                        <div className="input-group">
                            <label>Nationality</label>
                            <i className="fas fa-globe input-icon"></i>
                            <input type="text" name="nationality" value={formData.nationality} placeholder="Nationality" onChange={handleInputChange} required />
                        </div>
                        <div className="gender-selection">
                            <label>Gender</label>
                            <div className="gender-options">
                                <input type="radio" id="male" name="gender" value="Male" checked={formData.gender === 'Male'} onChange={handleInputChange} />
                                <label htmlFor="male">Male</label>

                                <input type="radio" id="female" name="gender" value="Female" checked={formData.gender === 'Female'} onChange={handleInputChange} />
                                <label htmlFor="female">Female</label>

                                <input type="radio" id="other" name="gender" value="Other" checked={formData.gender === 'Other'} onChange={handleInputChange} />
                                <label htmlFor="other">Other</label>
                            </div>
                        </div>

                        <div className="input-group">
                            <label>Upload Photo</label>
                            <i className="fas fa-image input-icon"></i>
                            <input type="file" name="photo" onChange={handleFileChange} required />
                        </div>
                        <div className="input-group">
                            <label>Age</label>
                            <i className="fas fa-calendar-day input-icon"></i>
                            <input type="number" name="age" value={formData.age} placeholder="Age" onChange={handleInputChange} required />
                        </div>
                        <div className="input-group">
                            <label>Standard</label>
                            <i className="fas fa-school input-icon"></i>
                            <input type="text" name="standard" value={formData.standard} placeholder="Standard" onChange={handleInputChange} required />
                        </div>
                        <div className="input-group">
                            <label>School Name</label>
                            <i className="fas fa-university input-icon"></i>
                            <input type="text" name="school_name" value={formData.school_name} placeholder="School Name" onChange={handleInputChange} required />
                        </div>
                        <div className="input-group">
                            <label>Father's Name</label>
                            <i className="fas fa-user input-icon"></i>
                            <input type="text" name="father_name" value={formData.father_name} placeholder="Father's Name" onChange={handleInputChange} required />
                        </div>
                        <div className="input-group">
                            <label>Father's Occupation</label>
                            <i className="fas fa-briefcase input-icon"></i>
                            <input type="text" name="father_occupation" value={formData.father_occupation} placeholder="Father's Occupation" onChange={handleInputChange} required />
                        </div>
                        <div className="input-group">
                            <label>Mother's Name</label>
                            <i className="fas fa-user input-icon"></i>
                            <input type="text" name="mother_name" value={formData.mother_name} placeholder="Mother's Name" onChange={handleInputChange} required />
                        </div>
                        <div className="input-group">
                            <label>Father's Mobile Number</label>
                            <i className="fas fa-phone input-icon"></i>
                            <input type="number" name="father_phone" value={formData.father_phone} placeholder="Father's Mobile Number" onChange={handleInputChange} required />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="form-step">
                        <h2>Address Details</h2>
                        <div className="input-group">
                            <label>Address</label>
                            <i className="fas fa-home input-icon"></i>
                            <input type="text" name="address" value={addressR.address} placeholder="Address" onChange={handleChange} required />
                        </div>
                        <div className="input-group">
                            <label>Street Name</label>
                            <i className="fas fa-street-view input-icon"></i>
                            <input type="text" name="street" value={addressR.street} placeholder="Street Name" onChange={handleChange} required />
                        </div>
                        <div className="input-group">
                            <label>Pincode</label>
                            <i className="fas fa-map-pin input-icon"></i>
                            <input type="number" name="pincode" value={addressR.pincode} placeholder="Pincode" onChange={handleChange} required />
                        </div>
                        <div className="input-group">
                            <label>State</label>
                            <i className="fas fa-flag input-icon"></i>
                            <input type="text" name="state" value={addressR.state} placeholder="State" onChange={handleChange} required />
                        </div>
                        <div className="input-group">
                            <label>Country</label>
                            <i className="fas fa-globe input-icon"></i>
                            <input type="text" name="country" value={addressR.country} placeholder="Country" onChange={handleChange} required />
                        </div>
                    </div>
                );
            case 3:
                return (
                    <>
                        <div className="form-step1">
                            <h2>Program Details</h2>
                            <div className="input-group">
                                <label>Preferred Center</label>
                                <i className="fas fa-map-marker-alt input-icon"></i>
                                <select name="center_name" value={formData.center_name} onChange={handleInputChange} required>
                                    <option value="">Select Centre</option>
                                    {options2.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="input-group">
                                <label>Level</label>
                                <i className="fas fa-graduation-cap input-icon"></i>
                                <select name="class_level" value={formData.class_level} onChange={handleInputChange} required>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Intermediate">Intermediate</option>
                                    <option value="Advanced">Advanced</option>
                                </select>
                            </div>

                        </div>
                        {/* update in new file */}
                        {selectedCenter && (
                            <div className="center-details">
                                <p><strong>Address:</strong> {selectedCenter.location}</p>
                                <div className="divider"></div> {/* Divider between location and timing */}

                                <div className='admission-time'>
                                    <div className='admission-time-list list-1'>
                                        <p><strong>Center Timing:</strong></p>
                                        <p className='admission-p'>Select Your Preffered Class Time</p>
                                        {console.log(selectedCenter.open_hours)}
                                        {JSON.parse(selectedCenter.open_hours) && JSON.parse(selectedCenter.open_hours).map((timeSlot, index) => (
                                            <div key={index} className="timing">
                                                <strong>{timeSlot.day}:</strong>
                                                <span>{timeSlot.startTime} - {timeSlot.endTime}</span>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        )}
                        {
                            selectedCenter && filteredTimings.length > 0 ?
                                <div>
                                    <h2 className='perthighlevelget'>Select a Class Timing:</h2>
                                    <div className='center-toastcontainer'>
                                    {renderTimings()}
                                    </div>
                                    
                                </div> : null
                        }


                    </>
                );
            case 4:
                return (
                    <Price age={formData.age} level={formData.class_level} center={formData.center_name} onPlanSelect={handlePlanSelect} />
                );
            default:
                return null;
        }
    };

    console.log(admission.status === "Pending" && paymentData.length > 0);
    if (admission.status === "Pending" && paymentData.length > 0) {
        return <>
            <Header />
            <div className="multi-step-form">
                <Text fontSize={"2xl"} padding={"20px"} borderRadius={"10px"} boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" color={"green"} fontWeight={"500"} textAlign={"center"}>

                    Your Admission is under process, you can check its status by clicking on below button
                </Text>

                <div class="arrow-container">
                    <div class="arrow"></div>
                </div>
                <Box textAlign={"center"}>
                    <Button mt={"40px"} bg="#1e90ff" color="white" _hover={{ bg: "#1e90ff", color: "white" }} onClick={() => navigate("/profile")}>Check Status</Button>
                </Box>
            </div>
            <Footer />
        </>
    } else if (admission.status === "Pending" && paymentData.length === 0) {
        return (
            <>
                <Header />
                <div className="multi-step-form">
                    <Text padding={"20px"} borderRadius={"10px"} boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" fontSize={"2xl"} color={"red"} fontWeight={"500"} textAlign={"center"}>
                        Your Payment is Pending, Select Payment Plan from the below option.

                    </Text>
                    <Price age={admission.age} level={admission.class_level} center={admission.center_name} onPlanSelect={handlePlanSelect} />
                    <Box textAlign={"center"}>
                        <Button bg="#1e90ff" color="white" _hover={{ bg: "#1e90ff", color: "white" }} onClick={handleClick}>PAY NOW</Button>
                    </Box>
                </div>
                <Footer />
            </>
        )
    }
    return (
        <>
            <Header />
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div className="multi-step-form">
                <div className="step-indicator">
                    <div className={`step ${step === 1 ? 'active' : step > 1 ? 'completed' : ''}`}>1</div>
                    <div className={`step ${step === 2 ? 'active' : step > 2 ? 'completed' : ''}`}>2</div>
                    <div className={`step ${step === 3 ? 'active' : step > 3 ? 'completed' : ''}`}>3</div>
                    <div className={`step ${step === 4 ? 'active' : ''}`}>4</div>
                </div>
                {renderStepContent()}
                <div className="button-group">
                    {step > 1 && <button type="button" className="btn-prev" onClick={prevStep}>Back</button>}
                    {step < 4 ? (
                        <button type="button" className="btn-next" onClick={nextStep}>Next</button>
                    ) : (
                        <button type="submit" className="btn-submit" onClick={sendAddmissionForm}>Submit</button>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default MultiStepForm;