import React, { useEffect, useState } from 'react';
import '../CSS/Global.css'; // Include your CSS file
import Header from './Header';
import Footer from './Footer';
import profile from "../IMG/profiledummy.avif"

const CalendarAndSchedule = () => {
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // Manage current month
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Manage current year
    const [attendanceData, setAttendanceData] = useState([]); // Store fetched attendance data
    const [classSchedule, setClassSchedule] = useState([]); // Store fetched class schedule data
    const [nextLecture, setNextLecture] = useState(null); // To store next lecture details
    const [centerName, setCenterName] = useState(''); // To store center name
    const [coachName, setCoachName] = useState('');
    const [admissionData,setAdmissionData]=useState({});

    let login = JSON.parse(localStorage.getItem("login")) || "";

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    // Fetch attendance data from the backend
    const fetchAbsentPresent = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/studentattendance/singlestudent/${login.emplyee_ID}`);
            const res = await response.json();
            console.log("Attendance Data:", res);
            setAttendanceData(res || []);
        } catch (error) {
            console.log("Error fetching attendance data:", error);
        }
    };
    

    // Fetch admission details to get class schedule
    const fetchAdmissiondetails = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/admission/${login.emplyee_ID}`);
            const res = await response.json();
            console.log(res)
            setAdmissionData(res);
            if (response.ok) {
                fetchClassSchedule(res.age, res.center_name, res.class_level); // Fetch class schedule based on admission details
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Fetch class schedule for the student
    const fetchClassSchedule = async (age, center, classLevel) => {
        try {

            console.log(age,center,classLevel)
            const response = await fetch(`https://100billionoms.top/classschedule/studentstime?age=${age}&center=${center}&classLevel=${classLevel}`);
            const res = await response.json();
            console.log("Class Schedule Response:", res);
            
            if (!res || res.length === 0) {
                console.log("No schedule found");
                return;
            }
                          
            setClassSchedule(res); // Store the class schedule
            setCenterName(center); // Set the center name
            setCoachName(res[0]?.class_coachname || ""); // Use the first schedule's coach name
        } catch (error) {
            console.log("Error fetching class schedule:", error);
        }
    };
    

    // Function to get all dates in the current month that match the class day
    // Function to find the next lecture





    // const calculateDuration = (start, end) => {
    //     const startTime = new Date(`1970-01-01T${start}`);
    //     const endTime = new Date(`1970-01-01T${end}`);
    //     const diff = (endTime - startTime) / 60000; // Convert to minutes

    //     console.log("Start Time:", startTime);
    //     console.log("End Time:", endTime);
    //     return `${diff} Minutes`;
    // };

    useEffect(() => {
        fetchAbsentPresent();
        fetchAdmissiondetails();
    }, []);


    const dayMap = {
        'Sunday': 0,
        'Monday': 1,
        'Tuesday': 2,
        'Wednesday': 3,
        'Thursday': 4,
        'Friday': 5,
        'Saturday': 6
    };
    // Function to filter and get upcoming lectures
    const getUpcomingLectures = (classSchedule) => {
        const today = new Date(); // Current date and time
        const upcomingLectures = [];
    
        if (!classSchedule || classSchedule.length === 0) {
            console.log("Class schedule is empty or undefined");
            return [];
        }
    
        classSchedule.forEach(schedule => {
            const coachDetails = typeof schedule.class_coach_details === 'string'
                ? JSON.parse(schedule.class_coach_details)
                : schedule.class_coach_details;
    
            const parsedFrequency = typeof schedule.frequency === 'string'
                ? JSON.parse(schedule.frequency)
                : schedule.frequency;
    
            if (!parsedFrequency || parsedFrequency.length === 0) {
                console.log(`No frequency found for class: ${schedule.class_name}`);
                return;
            }
    
            parsedFrequency.forEach(freq => {
                const dayOfWeekIndex = dayMap[freq.day];
                const scheduledDates = getScheduledDates(dayOfWeekIndex);
    
                scheduledDates.forEach(scheduledDate => {
                    const lectureDate = new Date(currentYear, currentMonth, scheduledDate);
    
                    const dateString = `${lectureDate.getFullYear()}-${String(lectureDate.getMonth() + 1).padStart(2, '0')}-${String(lectureDate.getDate()).padStart(2, '0')}`;
                    const startTime = new Date(`${dateString}T${freq.startTime}`);
                    const endTime = new Date(`${dateString}T${freq.endTime}`);
    
                    if (startTime >= today) {
                        const coachName = Array.isArray(coachDetails)
                            ? coachDetails.map(coach => `${coach.firstName} ${coach.lastName}`).join(', ')
                            : `${coachDetails?.firstName || ''} ${coachDetails?.lastName || ''}`;
    
                        upcomingLectures.push({
                            date: lectureDate,
                            start_time: startTime,
                            end_time: endTime,
                            class_name: schedule.class_name,
                            coach_name: coachName
                        });
                    }
                });
            });
        });
    
        console.log("Upcoming Lectures:", upcomingLectures);
        return upcomingLectures;
    };
 
    const getScheduledDates = (dayOfWeek) => {
        const firstDay = new Date(currentYear, currentMonth, 1);
        const lastDay = new Date(currentYear, currentMonth + 1, 0);
        const scheduledDates = [];

        for (let i = 1; i <= lastDay.getDate(); i++) {
            const date = new Date(currentYear, currentMonth, i);
            if (date.getDay() === dayOfWeek) {
                scheduledDates.push(i); // Add this day as a scheduled date
            }
        }

        return scheduledDates;
    };

    // Modify the existing useEffect to include this logic
    useEffect(() => {
        const firstDay = new Date(currentYear, currentMonth, 1);
        const lastDay = new Date(currentYear, currentMonth + 1, 0);
        const numDays = lastDay.getDate();

        const daysOfWeekArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        setDaysOfWeek(daysOfWeekArray);

        const datesArray = [];
        for (let i = 0; i < firstDay.getDay(); i++) {
            datesArray.push(''); // Fill empty spots for non-existing dates
        }

        // Fetch and filter upcoming lectures
        const upcomingLectures = getUpcomingLectures(classSchedule);
        console.log(attendanceData)
        // Loop over the days of the current month and assign classes
        for (let i = 1; i <= numDays; i++) {
            const currentDate = new Date(currentYear, currentMonth, i);
            let className = 'student-date';

            // Check if attendance data exists for the current date
            const attendanceForDay = attendanceData.find(attendance => {
                const attendanceDate = new Date(attendance.date);
                return (
                    attendanceDate.getFullYear() === currentYear &&
                    attendanceDate.getMonth() === currentMonth &&
                    attendanceDate.getDate() === i
                );
            });

            // If attendance exists, set class to present or absent
            if (attendanceForDay) {
                className += attendanceForDay.status === 'Present' ? ' student-present' : ' student-absent';
            } else {
                // Check if this day is a scheduled class in the future
                const today = new Date(); // Current date (today)

                // Loop through each class in the classSchedule and only consider future classes
                classSchedule.forEach(schedule => {
                    schedule.frequency.forEach(freq => {
                        const dayOfWeekIndex = dayMap[freq.day]; // Map day name to index
                        const scheduledDates = getScheduledDates(dayOfWeekIndex);

                        // If the current date is in the future or today, add student-scheduled class
                        if (scheduledDates.includes(i) && currentDate.setHours(0, 0, 0, 0) >= today.setHours(0, 0, 0, 0)) {
                            className += ' student-scheduled'; // Mark the date as a future scheduled class
                        }
                    });
                });

                // Check if the current day matches an upcoming class
                upcomingLectures.forEach(lecture => {
                    const lectureDate = new Date(lecture.start_time).getDate();
                    if (lectureDate === i) {
                        className += ' upcoming-lecture'; // Highlight upcoming class
                    }
                    // console.log(lectureDate)
                });
            }

            datesArray.push({ day: i, className });
        }

        setDates(datesArray);


    }, [currentYear, currentMonth, attendanceData, classSchedule]);


    // useEffect(() => {
    //     const today = new Date(); // Get today's date

    //     // Filter the upcoming lectures
    //     const upcomingLectures = getUpcomingLectures(classSchedule);
    //     console.log(upcomingLectures);
    //     // setNextLecture(upcomingLectures[0]);
    //     // Sort the lectures by start time to get the nearest upcoming one
    //     const sortedLectures = upcomingLectures.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));

    //     // Set the first lecture in the sorted list as the next upcoming lecture
    //     if (sortedLectures.length > 0) {
    //         setNextLecture(sortedLectures[0]);
    //     }
    // }, [classSchedule]);

    useEffect(() => {
        if (classSchedule.length > 0) {
            const upcomingLectures = getUpcomingLectures(classSchedule);
            if (upcomingLectures.length > 0) {
                const sortedLectures = upcomingLectures.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
                setNextLecture(sortedLectures[0]); // Set the next lecture
            } else {
                setNextLecture(null); // No upcoming lectures
            }
        }
    }, [classSchedule]);
    
console.log(nextLecture)
    // Function to calculate duration between start and end times
    const calculateDuration = (startTime, endTime) => {
        if (!startTime || !endTime) {
            return "N/A";
        }
        const start = new Date(startTime);
        const end = new Date(endTime);
        const diff = (end - start) / (1000 * 60); // Convert to minutes
    
        return `${diff} Minutes`;
    };
    

    const handleDateClick = (day) => {
        setSelectedDate(day);
    };

    const handlePrevMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(currentYear - 1);
        } else {
            setCurrentMonth(currentMonth - 1);
        }
    };

    const handleNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
        } else {
            setCurrentMonth(currentMonth + 1);
        }
    };

    console.log(nextLecture)

    return (
        <>
            <Header />
            <div className="student-main">
                <div className="student-container">
                    {/* Calendar Section */}
                    <div className="student-calendar">
                        <div className="student-calendar-header">
                            <button className="nav-button prev-button" onClick={handlePrevMonth}><i className="fa-solid fa-chevron-left"></i></button>
                            <span id="student-calendar-month">{months[currentMonth]} {currentYear}</span>
                            <button className="nav-button next-button" onClick={handleNextMonth}><i className="fa-solid fa-chevron-right"></i></button>
                        </div>
                        <div className="student-calendar-grid">
                            {daysOfWeek.map(day => (
                                <div className="student-day" key={day}>{day}</div>
                            ))}
                            {dates.map((date, index) => (
                                <div
                                    className={date.className}
                                    key={index}
                                    onClick={() => date.day && handleDateClick(date.day)}
                                >
                                    {date.day ? date.day : ''}
                                </div>
                            ))}
                        </div>
                        {/* Indicator Section */}
                        <div className="student-indicator">
                            <div className="indicator-item student-scheduled">
                                <div className="indicator-color"></div><p>Upcoming Class</p>
                            </div>
                            <div className="indicator-item student-present">
                                <div className="indicator-color"></div><p>Present</p>
                            </div>
                            <div className="indicator-item student-absent">
                                <div className="indicator-color"></div><p>Absent</p>
                            </div>
                        </div>
                    </div>

                    {/* Schedule Details Section */}
                    <div className="student-schedule-details">
                        <div className="student-info">
                        {
                            admissionData.photo===""||admissionData.photo ===null ?
                            <img src={profile} alt="Student" />:
                            <img src={`https://100billionoms.top/${admissionData.photo}`} alt="Student" />
                        }
                            
                            <div className="student-name">{login.full_name}</div>
                        </div>
                        {nextLecture && (
                            <>
                                <div className="student-class-details">
                                    <i className="fas fa-calendar-day"></i>
                                    <p><strong> {nextLecture.start_time.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}</strong><span>Date</span></p>
                                </div>
                                <div className="student-class-details">
                                    <i className="fas fa-clock"></i>
                                    <p><strong>{nextLecture.start_time.toLocaleTimeString()}</strong><span>Start Time</span></p>
                                </div>
                                <div className="student-class-details">
                                    <i className="fas fa-hourglass-half"></i>
                                    <p><strong>{calculateDuration(nextLecture.start_time, nextLecture.end_time)}</strong><span>Duration</span></p>
                                </div>
                                <div className="student-class-details">
                                    <i className="fas fa-building"></i>
                                    {console.log(nextLecture)}
                                    <p><strong>{nextLecture.class_name}</strong><span>Center</span></p>
                                </div>
                                <div className="student-class-details">
                                    <i className="fas fa-chalkboard-teacher"></i>
                                    <p><strong>{nextLecture.coach_name}</strong><span>Coach</span></p>
                                </div>
                            </>
                        )}
                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
};

export default CalendarAndSchedule;
