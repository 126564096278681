import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { IconButton, Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import { useJsApiLoader, useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";


let autoComplete;

// const loadScript = (url, callback) => {
//   let script = document.createElement("script");
//   script.type = "text/javascript";

//   if (script.readyState) {
//     script.onreadystatechange = function () {
//       if (script.readyState === "loaded" || script.readyState === "complete") {
//         script.onreadystatechange = null;
//         callback();
//       }
//     };
//   } else {
//     script.onload = () => callback();
//   }

//   script.src = url;
//   document.getElementsByTagName("head")[0].appendChild(script);
// };
const loadScript = (url) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;

        script.onload = () => {
            resolve();
        };

        script.onerror = () => {
            reject(new Error("Error loading Google Maps script"));
        };

        document.head.appendChild(script);
    });
};
const SearchAddress = ({ setSelectedLocation, address1, onQueryChange }) => {
    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);

    const { isLoaded } = useLoadScript({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU",
        libraries: ["places"]
    });

    const handleScriptLoad = (updateQuery, autoCompleteRef) => {
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            {
                // types: ["(cities)"],
                componentRestrictions: { country: "IN" },
            }
        );

        autoComplete.addListener("place_changed", () => {
            console.log("Suggestion selected!");
            handlePlaceSelect(updateQuery);
        });
    };

    const handlePlaceSelect = async (updateQuery) => {
        const addressObject = await autoComplete.getPlace();
         console.log(addressObject)
        const query = addressObject.formatted_address;
        updateQuery(query);
        console.log({ query });

        const latLng = {
            lat: addressObject?.geometry?.location?.lat(),
            lng: addressObject?.geometry?.location?.lng(),
        };

        console.log({ latLng });
        setSelectedLocation(latLng);
    };

    //   useEffect(() => {
    //     loadScript(
    //       `https://maps.googleapis.com/maps/api/js?key=AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU&libraries=places`,
    //       () => handleScriptLoad(setQuery, autoCompleteRef)
    //     );
    //   }, []);
    useEffect(() => {
        const loadGoogleMapsScript = async () => {
            try {
                await loadScript(
                    `https://maps.googleapis.com/maps/api/js?key=AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU&libraries=places`
                );

                // Check if the google.maps.places.Autocomplete object is available
                if (window.google && window.google.maps && window.google.maps.places) {
                    handleScriptLoad(setQuery, autoCompleteRef);
                } else {
                    console.error("Google Maps API not loaded");
                }
            } catch (error) {
                console.error("Error loading Google Maps script:", error);
            }
        };

        loadGoogleMapsScript();
    }, []);
    const [isInputEmpty, setIsInputEmpty] = useState(true);

    const handleClearInput = () => {
        setQuery("");
        setIsInputEmpty(true);
    };

    useEffect(() => {
        if (onQueryChange) {
            onQueryChange(query);
        }
    }, [query]);

    return (
        <div className="search-location-input" style={{ background: "white" }}>

            <InputGroup>
                <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.400" />} />
                <Input
                    ref={autoCompleteRef}
                    className="form-control"
                    onChange={(event) => {
                        setQuery(event.target.value);
                        setIsInputEmpty(event.target.value === "");
                    }}
                    placeholder={address1}
                    value={query}
                    fontWeight={"bold"}
                />
                 <InputRightElement width="4.5rem">
                {query && !isInputEmpty && (
                    <IconButton
                        aria-label="Clear input"
                        icon={<CloseIcon />}
                        onClick={handleClearInput}
                        variant="ghost"
                        color="gray.400"
                        size="sm"
                    />
                )}
            </InputRightElement>
            </InputGroup>
        </div>
    );
};

export default SearchAddress;