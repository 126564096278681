
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    useDisclosure,
    Input,
    Image,
    Text,
    Box,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Spinner
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react';
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import SearchAddress from './SearchAddress';
import Map from './Map';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';





const EditAddress = () => {
    let latitude = JSON.parse(localStorage.getItem("lat"))
    let longitude = JSON.parse(localStorage.getItem("lon"))
    let address = JSON.parse(localStorage.getItem("address"));
    let [orderplace, setOrderPlace] = useState("Home");
    let login = JSON.parse(localStorage.getItem("login")) || "";
    console.log(login)
    const [queryFromChild, setQueryFromChild] = useState("");
    let [address1, setAddress1] = useState("")
    let { id } = useParams();
    let [data, setData] = useState({})
    let navigate = useNavigate()
    let [load, setload] = useState(false);
    console.log(address1)
    const handleQueryFromChild = (query) => {
        setQueryFromChild(query);
    };

    console.log(address)
    console.log(typeof latitude, typeof longitude)
    const [selectedLocation, setSelectedLocation] = useState({
        lat: latitude,
        lng: longitude,
    });

    const { isOpen: isModal1Open, onOpen: openModal1, onClose: closeModal1 } = useDisclosure();



    console.log(queryFromChild);

    const storeData = (e) => {

        setData({ ...data, [e.target.name]: e.target.value });
    }

    let handleChange = (placeget) => {
        setOrderPlace(placeget);
        setData(prevData => ({ ...prevData, location_user: placeget }));

    }


    console.log(data)
    console.log(selectedLocation)
    useEffect(() => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${selectedLocation.lat},${selectedLocation.lng}&sensor=false&key=AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU`;
        fetch(url)
            .then((res) => res.json())
            .then((res) => {
                console.log(res);
                // setAddress(res.results[0].formatted_address);
                console.log(res.results[0].formatted_address);
                setAddress1(res.results[0].formatted_address)
            })
            .catch((error) => {
                console.log(error);

            });
    }, [selectedLocation])


    let fetchDataprofile = async () => {

        try {
            let response = await fetch(`https://100billionoms.top/address/single/${id}`)
            let res = await response.json();
            console.log(res.user);
            setData(res.user);

        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchDataprofile();
    }, [])

    let saveUserAddress = async (e) => {
        e.preventDefault();
        setload(true)
        try {
            const postData = {
                ...data,
                // Use address1 if queryFromChild is empty, otherwise use queryFromChild
                area: queryFromChild === "" ? data.area : queryFromChild,
            };
            console.log("postData:", data); // Log the postData object
            let response = await fetch(`https://100billionoms.top/address/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(postData),
            })
            let res = await response.json();
            navigate(-1)
            setload(false)
            console.log(res)
        } catch (error) {
            console.log(error);
            setload(false)
        }
    }
    let saveUserAddress1 = async (e) => {
        e.preventDefault();
        setload(true)
        try {
            const postData = {
                ...data,
                // Use address1 if queryFromChild is empty, otherwise use queryFromChild
                area: queryFromChild === "" ? data.area : queryFromChild,
            };
            console.log("postData:", data); // Log the postData object
            let response = await fetch(`https://100billionoms.top/address/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(postData),
            })
            let res = await response.json();
            setload(false)
            navigate("/address");
        } catch (error) {
            console.log(error);
            setload(false)
        }
    }
    return (
        <>
            <Box mb={"70px"}>
                <Header />
            </Box>
            <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "80%", xl: "80%" }}  m={"auto"} p={{ base: "0px", sm: "0px", md: "0px", lg: "20px", xl: "20px" }} boxShadow={"0 0 8px 0 rgba(0,0,0,0.1)"} borderRadius={"10px"}>
                <Flex direction={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                    <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "50%", xl: "50%" }} position={"relative"}>
                        <Map openModal1={openModal1} selectedLocation={selectedLocation} setAddress1={setAddress1} setSelectedLocation={setSelectedLocation} address1={address1} query={queryFromChild} onQueryChange={handleQueryFromChild} />
                    </Box>
                    <Box display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }} w={{ base: "100%", sm: "100%", md: "100%", lg: "50%", xl: "50%" }} h={"100vh"} overflowY={"auto"}>
                        <Box p={"14px 12px"} borderBottom={"1px solid gray"} >
                            <Flex alignItems={"center"}>
                                <Text fontSize={"17px"} fontWeight={"bold"}>Enter complete address</Text>

                            </Flex>
                        </Box>
                        <Box p={"24px"}>
                            <Text>Save address as *</Text>
                            <Box>
                                <Flex justifyContent={"space-between"}>
                                    <Box p={"6px"} borderRadius={"10px"}
                                        onClick={() => handleChange("Home")}
                                        cursor={"pointer"}
                                        bg={orderplace === "Home" ? "#E8F5E9" : "transparent"}
                                        border={orderplace === "Home" ? "1px solid green" : "1px solid transparent"}
                                        boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px">
                                        <Flex alignItems={"center"} gap={"5px"}>
                                            <i class="fa-solid fa-house-flag"></i>
                                            <Text fontSize={"12px"}>Home</Text>
                                        </Flex>
                                    </Box>
                                    <Box p={"6px"}
                                        onClick={() => handleChange("Work")}
                                        cursor={"pointer"}
                                        bg={orderplace === "Work" ? "#E8F5E9" : "transparent"}
                                        border={orderplace === "Work" ? "1px solid green" : "1px solid transparent"}
                                        borderRadius={"10px"} boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px">
                                        <Flex alignItems={"center"} gap={"5px"}>
                                            <i class="fa-solid fa-briefcase"></i>
                                            <Text fontSize={"12px"}>Work</Text>
                                        </Flex>
                                    </Box>
                                    <Box p={"6px"}
                                        onClick={() => handleChange("Hotel")}
                                        cursor={"pointer"}
                                        bg={orderplace === "Hotel" ? "#E8F5E9" : "transparent"}
                                        border={orderplace === "Hotel" ? "1px solid green" : "1px solid transparent"}
                                        borderRadius={"10px"} boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px">
                                        <Flex alignItems={"center"} gap={"5px"}>
                                            <i class="fa-solid fa-building"></i>
                                            <Text fontSize={"12px"}>Hotel</Text>
                                        </Flex>
                                    </Box>
                                    <Box p={"6px"}
                                        onClick={() => handleChange("Other")}
                                        cursor={"pointer"}
                                        bg={orderplace === "Other" ? "#E8F5E9" : "transparent"}
                                        border={orderplace === "Other" ? "1px solid green" : "1px solid transparent"}
                                        borderRadius={"10px"} boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px">
                                        <Flex alignItems={"center"} gap={"5px"}>
                                            <i class="fa-solid fa-location-dot"></i>
                                            <Text fontSize={"12px"}>Other</Text>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                        <Box p={"24px"}>
                            <form onSubmit={saveUserAddress}>
                                <Box>
                                    <Text mb={"5px"}>Flat/House No/Building name</Text>
                                    <Input type='text' name='flatno' value={data.flatno} onChange={storeData} isRequired />
                                </Box>
                                <Box mt={"10px"}>
                                    <Text mb={"5px"}>Floor (optional)</Text>
                                    <Input type='text' name='floor' value={data.floor} onChange={storeData} />
                                </Box>
                                <Box mt={"10px"}>
                                    <Text mb={"5px"}>Area/Sector/Locality</Text>
                                    <Input
                                        name='area'
                                        value={queryFromChild === "" ? data.area : queryFromChild}
                                        onChange={storeData}
                                        disabled />
                                </Box>
                                <Box mt={"10px"}>
                                    <Text mb={"5px"}>Nearby Landmark (optional)</Text>
                                    <Input type='text' name='landmark'
                                        value={data.landmark}
                                        onChange={storeData}
                                    />
                                </Box>
                                <Text mt="10px" fontSize={"15px"} textDecoration={"underline"} fontWeight={"500"}>Enter your details for seamless delivery experience</Text>
                                <Box mt={"10px"}>
                                    <Text >Email</Text>
                                    <Input type='text'
                                        name='email'
                                        p={"15px 30px 15px 12px"}
                                        value={data.email}
                                        onChange={storeData}
                                        placeholder='Your email'

                                    />
                                </Box>
                                <Box mt={"10px"}>
                                    <Text mb={"5px"}>Your Name</Text>
                                    <Input type='text'
                                        name='name'
                                        value={data.name}
                                        onChange={storeData}
                                        isRequired
                                    />
                                </Box>
                                <Box mt={"10px"}>
                                    <Text mb={"5px"}>Phone Number</Text>
                                    <Input type='text'
                                        name='number'
                                        value={data.number}
                                        onChange={storeData}
                                        isRequired
                                    />
                                </Box>
                                {
                                    load ? <Button mt={"20px"} bg={"green"} color={"white"} w={"100%"} type='submit'>
                                        <Spinner />
                                    </Button> : <Button mt={"20px"} bg={"green"} color={"white"} w={"100%"} type='submit'>
                                        Save Address
                                    </Button>
                                }
                            </form>
                        </Box>
                    </Box>
                </Flex>
            </Box>
            <Drawer
                isOpen={isModal1Open}
                placement='bottom'

                onClose={closeModal1}
                closeOnOverlayClick={false}
                closeOnEsc={false}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <form onSubmit={saveUserAddress1}>
                        <DrawerCloseButton />
                        <DrawerHeader fontSize={"15px"} borderBottomWidth='1px'>
                            Enter complete address
                        </DrawerHeader>

                        <DrawerBody>
                            <Box>
                                <Box w={{ base: "100%", sm: "100%", md: "100%" }} overflowY={"auto"}>

                                    <Box p={"0px 5px 0px 5px"}>
                                        <Text fontSize={"12px"} color={"#9C9C9C"}>Save address as *</Text>
                                        <Box mt={"5px"}>
                                            <Flex justifyContent={"space-between"}>
                                                <Box p={"6px"} borderRadius={"10px"}
                                                    onClick={() => handleChange("Home")}
                                                    cursor={"pointer"}
                                                    bg={orderplace === "Home" ? "#E8F5E9" : "transparent"}
                                                    border={orderplace === "Home" ? "1px solid green" : "1px solid transparent"}
                                                    boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px">
                                                    <Flex alignItems={"center"} gap={"5px"}>
                                                        <i class="fa-solid fa-house-flag fa-xs"></i>
                                                        <Text fontSize={"12px"}>Home</Text>
                                                    </Flex>
                                                </Box>
                                                <Box p={"6px"}
                                                    onClick={() => handleChange("Work")}
                                                    cursor={"pointer"}
                                                    bg={orderplace === "Work" ? "#E8F5E9" : "transparent"}
                                                    border={orderplace === "Work" ? "1px solid green" : "1px solid transparent"}
                                                    borderRadius={"10px"} boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px">
                                                    <Flex alignItems={"center"} gap={"5px"}>
                                                        <i class="fa-solid fa-briefcase fa-xs"></i>
                                                        <Text fontSize={"12px"}>Work</Text>
                                                    </Flex>
                                                </Box>
                                                <Box p={"6px"}
                                                    onClick={() => handleChange("Hotel")}
                                                    cursor={"pointer"}
                                                    bg={orderplace === "Hotel" ? "#E8F5E9" : "transparent"}
                                                    border={orderplace === "Hotel" ? "1px solid green" : "1px solid transparent"}
                                                    borderRadius={"10px"} boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px">
                                                    <Flex alignItems={"center"} gap={"5px"}>
                                                        <i class="fa-solid fa-building fa-xs"></i>
                                                        <Text fontSize={"12px"}>Hotel</Text>
                                                    </Flex>
                                                </Box>
                                                <Box p={"6px"}
                                                    onClick={() => handleChange("Other")}
                                                    cursor={"pointer"}
                                                    bg={orderplace === "Other" ? "#E8F5E9" : "transparent"}
                                                    border={orderplace === "Other" ? "1px solid green" : "1px solid transparent"}
                                                    borderRadius={"10px"} boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px">
                                                    <Flex alignItems={"center"} gap={"5px"}>
                                                        <i class="fa-solid fa-location-dot fa-xs"></i>
                                                        <Text fontSize={"12px"}>Other</Text>
                                                    </Flex>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Box>
                                    <Box p={"5px"} mt={"10px"}>

                                        <Box>
                                            <Text color={"#9C9C9C"} fontSize={"12px"}>Flat/House No/Building name</Text>
                                            <Input p={"15px 30px 15px 12px"} type='text' name='flatno' placeholder='Flat/House No/Building name' value={data.flatno} onChange={storeData} isRequired />
                                        </Box>
                                        <Box mt={"10px"}>
                                            <Text color={"#9C9C9C"} fontSize={"12px"}>Floor (optional)</Text>
                                            <Input p={"15px 30px 15px 12px"} type='text' name='floor' placeholder='Floor (optional)' value={data.floor} onChange={storeData} />
                                        </Box>
                                        <Box mt={"10px"}>
                                            <Text color={"#9C9C9C"} fontSize={"12px"}>Area/Sector/Locality</Text>
                                            <Input

                                                p={"15px 30px 15px 12px"}
                                                name='area'
                                                value={queryFromChild === "" ? data.area : queryFromChild}
                                                onChange={storeData}
                                                disabled />
                                        </Box>
                                        <Box mt={"10px"}>
                                            <Text color={"#9C9C9C"} fontSize={"12px"}>Nearby Landmark (optional)</Text>
                                            <Input type='text' name='landmark'
                                                value={data.landmark}
                                                onChange={storeData}
                                                placeholder='Nearby Landmark (optional)'
                                            />
                                        </Box>
                                        <Text mt="8px" fontSize={"12px"}>Enter your details for seamless delivery experience</Text>
                                        <Box mt={"5px"}>
                                            <Text color={"#9C9C9C"} fontSize={"12px"}>Your Name</Text>
                                            <Input type='text'
                                                name='name'
                                                p={"15px 30px 15px 12px"}
                                                value={data.name}
                                                onChange={storeData}
                                                placeholder='Your Name'
                                                isRequired
                                            />
                                        </Box>
                                        <Box mt={"10px"}>
                                            <Text color={"#9C9C9C"} fontSize={"12px"}>Phone Number</Text>
                                            <Input type='text'
                                                name='number'
                                                p={"15px 30px 15px 12px"}
                                                value={data.number}
                                                onChange={storeData}
                                                isRequired
                                            />
                                        </Box>


                                    </Box>
                                </Box>
                            </Box>
                        </DrawerBody>
                        <DrawerFooter borderTopLeftRadius={"12px"} borderTopRightRadius={"12px"} boxShadow={"rgba(0, 0, 0, 0.25) 0px 4px 15px 0px;"}>
                            <Button bg={"green"} color={"white"} w={"100%"} type='submit'>
                                Save Address
                            </Button>
                        </DrawerFooter>
                    </form>
                </DrawerContent>
            </Drawer>
            <Box mt={"30px"}>
                <Footer />
            </Box>
        </>
    )
}

export default EditAddress; 