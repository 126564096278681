import React, { useState } from 'react';
import CountUp from 'react-countup'; // Import CountUp
import '../CSS/Global.css'; 
import Header from './Header'; 
import Footer from './Footer';
import aboutImg from '../IMG/about-mian.webp' 

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState('Mission'); // To control active tab

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Mission':
        return (
          <div className="about-tab-content">
            <h3>Our Mission</h3>
            <p>
              Our mission is to provide top-quality legal briefs, leveraging expertise and precision to assist attorneys in their legal pursuits. We aim to enhance the efficiency of legal writing while maintaining the highest standards of accuracy and professionalism.
            </p>
          </div>
        );
      case 'Vision':
        return (
          <div className="about-tab-content">
            <h3>Our Vision</h3>
            <p>
              Our vision is to be the leading provider of legal solutions, fostering innovation and cutting-edge technology to transform how legal professionals create and manage legal documents. We envision a future where legal writing is faster, more efficient, and easily accessible.
            </p>
          </div>
        );
      case 'Our Value':
        return (
          <div className="about-tab-content">
            <h3>Our Value</h3>
            <p>
              We value integrity, accuracy, and client satisfaction above all. By integrating modern technology with legal expertise, we aim to deliver comprehensive solutions that meet the evolving needs of the legal industry.
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Header />
      <div className="about-section">
        <div className="about-intro">
          <h1>About Us</h1>
          <p>Read more about us. Our vision, mission, success, and many other things you might love</p>
        </div>

        <section className="about-details">
          <div className="about-legal-briefs">
            <h2>We help on creating Legal briefs</h2>
            <p>We recognize the importance of legal writing to the legal profession and take our role in assisting attorneys seriously...</p>
            <div className="about-tabs">
              <button
                className={`about-tab ${activeTab === 'Mission' ? 'about-tab-active' : ''}`}
                onClick={() => setActiveTab('Mission')}
              >
                Mission
              </button>
              <button
                className={`about-tab ${activeTab === 'Vision' ? 'about-tab-active' : ''}`}
                onClick={() => setActiveTab('Vision')}
              >
                Vision
              </button>
              <button
                className={`about-tab ${activeTab === 'Our Value' ? 'about-tab-active' : ''}`}
                onClick={() => setActiveTab('Our Value')}
              >
                Our Value
              </button>
            </div>
            {renderTabContent()}
          </div>
          <div className="about-person-image">
            <img src={aboutImg} alt="Person" />
          </div>
        </section>

        <section className="about-application-look">
          <h2>Take A Vital Look At Our Application working</h2>
          <div className="about-video-section">
            <img src="https://picsum.photos/800/450" alt="Team working" />
            <button className="about-play-button">▶</button>
          </div>
        </section>

        <section className="about-stats-section">
          <div className="about-stat">
            <h3><CountUp end={10} duration={2} />+</h3>
            <p>Years Experience</p>
          </div>
          <div className="about-stat">
            <h3><CountUp end={99} suffix="%" duration={2} /></h3>
            <p>Accuracy Rate</p>
          </div>
          <div className="about-stat">
            <h3><CountUp end={500} duration={2} />+</h3>
            <p>Positive Reviews</p>
          </div>
          <div className="about-stat">
            <h3><CountUp end={600} duration={2} />+</h3>
            <p>Trusted Partners</p>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
