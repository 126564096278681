import { useNavigate } from 'react-router-dom';
import '../CSS/Global.css';

const CartIcon = ({cartItems}) =>{
    console.log(cartItems);
    const navigate=useNavigate("")
    return(
        <>
            <div className="cart-icon" onClick={()=>navigate("/cart")}>
                <i className="fas fa-shopping-cart"></i>
                <span className="cart-count">{cartItems.length}</span>
            </div>
        </>
    );
};


export default CartIcon;