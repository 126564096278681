import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../CSS/Global.css'; // Assuming you have styles in a CSS file
import logo from "../IMG/logo-removebg-preview.png";
import img1 from "../IMG/img1.jpg"


const Header = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [admission, setAddmission] = useState({});


    let login = JSON.parse(localStorage.getItem("login")) || "";
    let navigate = useNavigate("");

    // Toggle Drawer
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    // Toggle Dropdown
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };


    const fetchDataAdmission = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/admission/singlead/${login.emplyee_ID}`)
            const res = await response.json();
            console.log(res);
            setAddmission(res);
        } catch (error) {
            console.log(error);
        }
    }

    // Close dropdown if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.matches('.chevron-icon') && !event.target.closest('.dropdown')) {
                setDropdownOpen(false);
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        fetchDataAdmission();
    }, [])
    const handleLogoutClick = () => {
        setShowModal(true); // Show the modal
    };

    const handleCancel = () => {
        setShowModal(false); // Close modal on cancel
    };

    const handleConfirmLogout = () => {
        localStorage.removeItem("login");
        setShowModal(false); // Close modal after confirming logout
        navigate("/login");
    };





    return (
        <div className="bg-color">
            <header>
                <div className="header-container">
                    {/* Hamburger Toggle */}
                    <div className="header-col hamburger">
                        <div className={`hamburger-toggle ${drawerOpen ? 'open' : ''}`} onClick={toggleDrawer}>
                            <div className="bar bar1"></div>
                            <div className="bar bar2"></div>
                            <div className="bar bar3"></div>
                        </div>
                        <span className="menu-text">Menu</span>
                        <div className="logo">
                            <img src={logo} alt="Logo" />
                        </div>
                    </div>

                    {/* User Info and admission button*/}

                    <div className="header-col user-info" id="user-info">
                        <div className="header-admission-button">
                            <Link to='/admission'>
                            <button> <i class="fa-solid fa-plus"></i> Admission</button>
                            </Link>
                        </div>

                        {login ? (
                            <div className="user-logged-in">
                                {
                                    (!login.profilePic || login.profilePic === "")
                                        ? <img src={img1} alt="User" className="theme-color-blue-img img-fluid avatar avatar-50 avatar-rounded" />
                                        : <img
                                            src={
                                                login.profilePic && login.profilePic.startsWith("http")
                                                    ? login.profilePic
                                                    : login.profilePic && login.profilePic.startsWith("Images\\StaffProfile\\")
                                                        ? `https://100billionoms.top/${login.profilePic.replace("\\", "/")}`
                                                        : img1
                                            }
                                            alt="User-Profile"
                                            className="theme-color-blue-img img-fluid avatar avatar-50 avatar-rounded"
                                            loading="lazy"
                                        />
                                }

                                <span className="username">{login.full_name}</span>
                                <div className="dropdown">
                                    <div className={`chevron-icon ${dropdownOpen ? 'rotate' : ''}`} onClick={toggleDropdown}>
                                        <i className="fas fa-chevron-down"></i>
                                    </div>
                                    <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
                                        <Link to="/profile?tab=profileUserInfo"><i className="fas fa-user"></i> Profile</Link>
                                        <Link to="/schedule"><i className="fas fa-calendar-check"></i>Upcoming Class</Link>
                                        <Link to="/profile?tab=profilePaymentInfo">
                                            <i className="fas fa-calendar-check"></i> Payment History
                                        </Link>
                                        <Link to="/orders"><i class="fa-solid fa-bag-shopping"></i>Orders</Link>
                                        <Link onClick={handleLogoutClick}><i className="fas fa-user"></i> Logout</Link>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="user-not-logged-in">
                                <Link to="/login" className="header-login-btn">
                                    <button> <i className="fas fa-sign-in-alt"></i> Login</button>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </header>

            {/* Drawer Menu */}
            <div className={`drawer ${drawerOpen ? 'open' : ''}`} id="drawer">
                <Link to="/"><i className="fas fa-home"></i> Home</Link>

                {
                    admission.status === "Approved" ? <Link to="/schedule"><i className="fas fa-calendar"></i> Scheduled</Link>
                        : null
                }
                {
                    admission.status === "Approved" ? <Link to="/profile"><i className="fas fa-user-graduate"></i> Admission</Link>
                        : <Link to="/admission"><i className="fas fa-user-graduate"></i> Admission</Link>
                }
                {/* <Link to="/admission"><i className="fas fa-user-graduate"></i> Admission</Link> */}
                <Link to="/equipment"><i className="fas fa-skating"></i> Equipments</Link>
                <Link to="/cart"><i className="fas fa-shopping-cart"></i> Cart</Link>
                <Link to="/enquiry"><i className="fa-solid fa-question"></i> Enquiry</Link>
                <Link to="/about"><i className="fas fa-info-circle"></i> About Us</Link>
                <Link to="/help"><i className="fa-solid fa-info"></i> Help</Link>
                <Link to="/contact"><i className="fas fa-envelope"></i> Contact Us</Link>
            </div>

            {/* Overlay */}
            <div className={`overlay ${drawerOpen ? 'show' : ''}`} id="overlay" onClick={toggleDrawer}></div>

            {/* Logout Confirmation Modal */}
            {showModal && (
                <div className="logout-popup">
                    <div className="logout-popup-content">
                        <h3>Do you want to logout?</h3>
                        <div className="logout-popup-buttons">
                            <button onClick={handleCancel}>Cancel</button>
                            <button onClick={handleConfirmLogout}>Logout</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;
