import React, { useState, useRef, useEffect } from 'react';
import '../CSS/Global.css'; // Ensure the CSS file matches this path
import Header from './Header';
import Footer from './Footer';
import PaymentComponent from '../Components/Payment'; // Import the PaymentComponent

const ProfileComponent = () => {
  const [activeTab, setActiveTab] = useState('profileUserInfo'); // Tracks the active tab
  const [profileImage, setProfileImage] = useState('https://picsum.photos/100'); // Default profile image
  const [isEditing, setIsEditing] = useState(false); // Tracks editing mode
  const fileInputRef = useRef(null);
  const [admission, setAdmissionForm] = useState({
    full_name: '',
    email: '',
    phone: '',
    dob: '',
    class_level: '',
    Students_id: '',
    center_name: '',
    photo: '',
    status: ''
  });

  let login = JSON.parse(localStorage.getItem('login')) || '';

  const fetchAdmissionForm = async () => {
    try {
      const response = await fetch(
        `https://100billionoms.top/admission/singlead/${login.emplyee_ID}`
      );
      const res = await response.json();
      setAdmissionForm(res);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAdmissionForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSave = async () => {
    try {
      const response = await fetch(
        `https://100billionoms.top/admission/change/${admission.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(admission),
        }
      );
      const updatedData = await response.json();
      setAdmissionForm(updatedData);
      fetchAdmissionForm();
      setIsEditing(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAdmissionForm();
  }, []);


  useEffect(() => {
    const handleTabChange = (e) => {
      const { tab } = e.detail; // Get the tab from the event's detail
      setActiveTab(tab);
    };

    window.addEventListener("tabChange", handleTabChange);

    return () => {
      window.removeEventListener("tabChange", handleTabChange);
    };
  }, []);

  
  useEffect(() => {
    // Set active tab based on URL query parameter
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get('tab') || 'profileUserInfo';
    setActiveTab(tab);
  }, [window.location.search]);

  const handleTabChange = (tab) => {
    setActiveTab(tab); // Update active tab state
    window.history.pushState({}, '', `/profile?tab=${tab}`); // Update URL without page reload
  };

  console.log(activeTab)

  return (
    <>
      <Header />
      <div className="profile-container">
        <div className="profile-content">
          {/* Column 1 */}
          <div className="profile-column profile-column-1">
            <div className="profile-box">
              <div className="profile-name">
                <strong>{admission.full_name}</strong>
              </div>
              <div className="profile-image-container">
                <img
                  src={
                    admission.photo === ''
                      ? profileImage
                      : `https://100billionoms.top/${admission.photo}`
                  }
                  alt="Profile"
                  className="profile-image"
                />
              </div>
              <button
                className={
                  admission.status === 'Pending'
                    ? 'admissionbutton'
                    : 'admissionapprove'
                }
              >
                {admission.status}
              </button>
              {isEditing && (
                <button
                  className="profile-upload-button"
                  onClick={() => fileInputRef.current.click()}
                >
                  <i className="fa-solid fa-upload"></i> Upload Your Photo
                </button>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
          </div>

          {/* Column 2 */}
          <div className="profile-column profile-column-2">
            <div className="profile-tabs">
              <button
                className={`profile-tab-button ${activeTab === 'profileUserInfo' ? 'profile-active' : ''}`}
                onClick={() =>
                  window.dispatchEvent(
                    new CustomEvent("tabChange", { detail: { tab: "profileUserInfo" } })
                  )
                }
              >
                <i className="fa-solid fa-user"></i> User Info
              </button>
              <button
                
                  className={`profile-tab-button ${activeTab === 'profilePaymentInfo' ? 'profile-active' : ''}`}
                  onClick={() =>
                    window.dispatchEvent(
                      new CustomEvent("tabChange", { detail: { tab: "profilePaymentInfo" } })
                    )
                  }
              >
                <i className="fa-solid fa-credit-card"></i> Payment Information
              </button>
            </div>
            <div className="profile-tab-content">
              {activeTab === 'profileUserInfo' ? (
                <div className="profile-user-info-form">
                  <div className="profile-form-group">
                    <label>
                      <i className="fa-solid fa-envelope"></i> Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      value={admission.email}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="profile-form-group">
                    <label>
                      <i className="fa-solid fa-phone"></i> Phone Number
                    </label>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone Number"
                      value={admission.phone}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="profile-form-group">
                    <label>
                      <i className="fa-solid fa-calendar-day"></i> Date of Birth
                    </label>
                    <input
                      type="date"
                      name="dob"
                      placeholder="Date of Birth"
                      value={admission.dob}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="profile-form-group">
                    <label>
                      <i className="fa-solid fa-layer-group"></i> Class Level
                    </label>
                    <input
                      type="text"
                      name="class_level"
                      placeholder="Class Level"
                      value={admission.class_level}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </div>
                  {isEditing && (
                    <button className="save-button" onClick={handleSave}>
                      Save
                    </button>
                  )}
                </div>
              ) : (
                <PaymentComponent />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProfileComponent;
