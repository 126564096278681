import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../CSS/Global.css';
import Footer from './Footer';
import Header from './Header';

const ComingSoon = () => {
  const [email, setEmail] = useState(''); // State to hold email input

  const validateEmail = (email) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubscribe = () => {
    if (!email) {
      toast.error('Type your email ID first'); // Show error toast for empty input
    } else if (!validateEmail(email)) {
      toast.error('Please enter a valid email address'); // Show error toast for invalid email
    } else {
      toast.success('Subscription successful!'); // Show success toast
      setEmail(''); // Clear email input
    }
  };

  return (
    <>
      <Header />
      <div className="coming-soon">
        <h1>COMING SOON</h1>
        <p>
          Something exciting is on its way! Stay tuned as we prepare to bring
          you an amazing experience.
        </p>

        <div className="coming-input">
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update email state
          />
          <button onClick={handleSubscribe}>Notify Me</button>
        </div>

        <p className="notify">Notify me when the page is launched</p>
      </div>
      <Footer />

      {/* Toastify container */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default ComingSoon;
