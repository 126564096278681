import React, { useState } from 'react';
import '../CSS/Global.css';
import Header from './Header';
import Footer from './Footer';

const Help = () => {
    const [selectedQuestion, setSelectedQuestion] = useState("enroll");

    // Answer options for each question
    const answers = {
        "enroll": "Visit our website and navigate to the 'Enroll Now' page. Select your preferred class, fill out the enrollment form, and complete the payment process.",
        "bringForLesson": "Bring comfortable athletic wear, socks, and any safety gear you own (helmets, knee pads, elbow pads). If you don't have gear, rentals or purchase may be available.",
        "minAge": "The minimum age for enrollment is 5 years old. We also offer classes for adults.",
        "skatesProvided": "Skates are available for rent at the academy. However, we recommend purchasing your own for better comfort and hygiene.",
        "missedClass": "Notify us in advance if possible. Missed classes can usually be rescheduled depending on availability.",
        "makeupSessions": "Yes, we offer makeup sessions. Please contact the support team to schedule a suitable time.",
        "loginIssue": "Click the 'Forgot Password' link on the login page, and follow the instructions to reset your password. If the issue persists, contact support.",
        "resetPassword": "On the login page, click 'Forgot Password,' enter your registered email, and follow the link sent to reset your password.",
        "paymentIssue": "Refresh the page or try accessing it in a different browser. If the problem persists, contact support for assistance.",
        "confirmationEmail": "Check your spam/junk folder. If you still don’t see the email, contact us with your registration details.",
        "safetyGear": "Helmets, knee pads, elbow pads, and wrist guards are essential for safe skating.",
        "gearProvided": "Yes, safety gear is available for rent or purchase at the academy.",
        "injuryDuringClass": "Our instructors are trained in first aid and will provide immediate assistance. We also have a protocol to inform parents and escalate care if needed.",
        "instructorsFirstAid": "Yes, all our instructors are first-aid certified to ensure the safety of our participants.",
        "classSchedules": "Login to your account on our website, and navigate to the 'My Schedule' section to view upcoming classes.",
        "changeClassTiming": "Yes, you can request a schedule change by contacting the support team. Changes depend on class availability.",
        "runningLate": "Please inform the academy or your instructor if you’ll be late. They will do their best to accommodate you.",
        "privateLessons": "Yes, we offer private lessons. Contact us to book a session at a time convenient for you.",
        "paymentMethods": "We accept credit cards, debit cards, UPI payments, and online banking.",
        "refundPolicy": "Refunds are available if you cancel within the first week of enrollment. Please refer to our refund policy for details.",
        "familyDiscount": "Yes, we offer family discounts. Contact support for more information.",
        "paymentHistory": "Login to your account and navigate to the 'Payment History' section to view all transactions.",
        "basicSkating": "Most beginners learn basic skills within 4-6 sessions, depending on individual progress.",
        "readyForAdvanced": "Your instructor will evaluate your skills and recommend advancing when you're ready.",
        "certificationsCompetitions": "Yes, we provide certifications upon completion of courses and organize competitions regularly.",
        "parentsWatching": "Absolutely! Parents are welcome to watch from designated viewing areas.",
        "nearestBranch": "Visit our website’s 'Locations' page to find the nearest branch.",
        "indoorOutdoorRinks": "Yes, we offer both indoor and outdoor skating facilities.",
        "parkingAvailable": "Yes, free parking is available at most of our locations.",
        "hygieneProtocols": "All shared equipment is sanitized after every use to ensure cleanliness and safety.",
        "skatingEvents": "Yes, we host events and competitions throughout the year. Check our 'Events' page for upcoming dates.",
        "seasonalCamps": "Yes, we organize seasonal camps during summer and winter breaks. Early registration is recommended.",
        "privateEventBooking": "Yes, our facilities are available for private bookings. Contact us for pricing and availability.",
        "volunteerEvents": "Reach out to us through the 'Contact Us' page to sign up as a volunteer.",
        "updateInfo": "Login to your account and navigate to the 'Profile' section to update your details.",
        "transferBranch": "Yes, transfers are possible. Contact support for assistance.",
        "pauseMembership": "Contact support to request a temporary pause. We’ll adjust your schedule accordingly."
    };

    const handleSelectChange = (event) => {
        setSelectedQuestion(event.target.value);
    };

    return (
        <>
            <Header />
            <div className="help">
                <div className="help-container">
                    <div className="help-left">
                        <h1>Master Skating with <span>Radical Sports Academy</span></h1>
                    </div>
                    <div className="help-right">
                        <h2>How can we help you?</h2>
                        <select onChange={handleSelectChange} className="question-select">
                            <option value="enroll">How do I enroll myself or my child in a skating class?</option>
                            <option value="bringForLesson">What should I bring for my first skating lesson?</option>
                            <option value="minAge">What is the minimum age requirement for joining the academy?</option>
                            <option value="skatesProvided">Do I need to purchase my own roller skates, or does the academy provide them?</option>
                            <option value="missedClass">What should I do if I miss a class?</option>
                            <option value="makeupSessions">Are there makeup sessions available for missed classes?</option>
                            <option value="loginIssue">I’m unable to log into my account. What should I do?</option>
                            <option value="resetPassword">How can I reset my password?</option>
                            <option value="paymentIssue">The payment page isn’t loading—how can I complete my registration?</option>
                            <option value="confirmationEmail">I didn’t receive a confirmation email after signing up—what should I do?</option>
                            <option value="safetyGear">What safety equipment do I need for skating?</option>
                            <option value="gearProvided">Does the academy provide helmets, knee pads, and other protective gear?</option>
                            <option value="injuryDuringClass">What happens if my child gets injured during a class?</option>
                            <option value="instructorsFirstAid">Are instructors trained in first aid?</option>
                            <option value="classSchedules">How can I check my class schedule?</option>
                            <option value="changeClassTiming">Can I change my class timing?</option>
                            <option value="runningLate">What should I do if I’m running late to a class?</option>
                            <option value="privateLessons">Are private skating lessons available?</option>
                            <option value="paymentMethods">What payment methods do you accept?</option>
                            <option value="refundPolicy">Can I get a refund if I decide to cancel my enrollment?</option>
                            <option value="familyDiscount">Is there a discount for enrolling multiple family members?</option>
                            <option value="paymentHistory">How can I view my payment history?</option>
                            <option value="basicSkating">How long will it take to learn basic skating skills?</option>
                            <option value="readyForAdvanced">How do I know if I’m ready to move to an advanced level?</option>
                            <option value="certificationsCompetitions">Do you offer certifications or competitions for skaters?</option>
                            <option value="parentsWatching">Can parents watch the classes?</option>
                            <option value="nearestBranch">Where is the nearest academy branch?</option>
                            <option value="indoorOutdoorRinks">Are there indoor and outdoor skating facilities available?</option>
                            <option value="parkingAvailable">Is there parking available near the skating rink?</option>
                            <option value="hygieneProtocols">What are the hygiene protocols for shared equipment?</option>
                            <option value="skatingEvents">Do you host skating events or competitions?</option>
                            <option value="seasonalCamps">Are there seasonal skating camps?</option>
                            <option value="privateEventBooking">Can I book the skating rink for a private event or party?</option>
                            <option value="volunteerEvents">How can I volunteer for academy events?</option>
                            <option value="updateInfo">How can I update my personal information on the website?</option>
                            <option value="transferBranch">Can I transfer my enrollment to another branch?</option>
                            <option value="pauseMembership">What should I do if I want to pause my membership temporarily?</option>
                        </select>
                        <div className="answer-container">
                            {selectedQuestion && <p>{answers[selectedQuestion]}</p>}
                        </div>
                        <p>For more queries contact us: <br /> <span><i className="fa-solid fa-envelope"></i><a href="mailto:Info@radicalsportsindia.com">Info@radicalsportsindia.com</a> <span className="line">|</span> <i className="fa-solid fa-phone"></i><a href="tel:+919820163394">+919820163394</a></span>
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Help;
