import { Navigate } from "react-router-dom";


function ProtectedRoute({children}){
    const isAuthenticated = localStorage.getItem('login')||""
    console.log(isAuthenticated)
      // If `login` exists, render children; otherwise, redirect to the login page
      return isAuthenticated ? children : <Navigate to="/login" />
}

export default ProtectedRoute;