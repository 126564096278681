import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for internal navigation
import logo from '../IMG/logo-removebg-preview.png'; // Adjust path to your logo image

const Footer = () => {
  return (
    <footer className="ft-container" id="footer">
      <div className="ft-section">
        <img src={logo} alt="Radical sports logo" className="ft-logo" />
        <p className="ft-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      </div>
      <div className="ft-section">
        <h3 className="ft-title">Quick Links</h3>
        <ul className="ft-list">
          <li className="ft-item"><Link to="/about" className="ft-link">About Us</Link></li>
          <li className="ft-item"><Link to="/coming-soon" className="ft-link">Events</Link></li>
          <li className="ft-item"><Link to="/coming-soon" className="ft-link">Match Schedule</Link></li>
          <li className="ft-item"><Link to="/coming-soon" className="ft-link">Facilities</Link></li>
        </ul>
      </div>
      <div className="ft-section">
        <h3 className="ft-title">Extra Links</h3>
        <ul className="ft-list">
          <li className="ft-item"><Link to="/terms" className="ft-link">Terms & Conditions</Link></li>
          <li className="ft-item"><Link to="/privacy" className="ft-link">Privacy Policy</Link></li>
          <li className="ft-item"><Link to="/contact" className="ft-link">Help</Link></li>
          <li className="ft-item"><Link to="/coming-soon" className="ft-link">FAQ</Link></li>
          <li className="ft-item"><Link to="/coming-soon" className="ft-link">Blog</Link></li>
        </ul>
      </div>
      <div className="ft-section">
        <h3 className="ft-title">Get in Touch</h3>
        <div className="ft-contact">
          <i className="fa fa-phone ft-icon"></i> +919820163394
        </div>
        <div className="ft-contact">
          <i className="fa fa-envelope ft-icon"></i> Info@radicalsportsindia.com
        </div>
        <div className="ft-contact">
          <i className="fa fa-map-marker ft-icon"></i> Malad East Mumbai India 400097
        </div>
        <div className="ft-socials">
          <a href="https://facebook.com" className="ft-social-link" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-facebook"></i>
          </a>
          <a href="https://instagram.com" className="ft-social-link" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a href="https://youtube.com" className="ft-social-link" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-youtube"></i>
          </a>
        </div>
      </div>
      <div className="ft-bottom">
        2024 ©  Powered by :{' '}
        <a href="https://100billiontech.com/" target="_blank" rel="noopener noreferrer">100billion Tech</a>
      </div>
    </footer>
  );
};

export default Footer;
