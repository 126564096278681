import React, { useEffect, useState } from 'react';
import '../CSS/Global.css';
// import Header from './Header';
// import Footer from './Footer';

const PaymentComponent = () => {
    const [filterText, setFilterText] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterMonth, setFilterMonth] = useState('');
    const [fees, setFees] = useState([])
    const [totalpaid, settotalPaid] = useState()
    const [remainingbalance, setRemaining] = useState()
    const [totalAmount, setTotalAmount] = useState()
    let login = JSON.parse(localStorage.getItem("login")) || "";
    // Expanded dataset with more than 50 entries
    const paymentData = Array.from({ length: 5 }, (_, index) => ({
        amount: `₹${(Math.random() * 50000).toFixed(2)}`,
        date: `2024-09-${(index % 30) + 1}`, // You can modify this to reflect different months if needed
        status: Math.random() > 0.5 ? 'Completed' : 'Pending',
    }));

    const paymentSummary = {
        totalAmount: totalAmount / 100,
        paidAmount: totalpaid / 100,
        dueAmount: remainingbalance / 100,
        completionPercentage: (1250 / 1350) * 100,
    };


    const PaymentdetailsFetch = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/feespay/${login.emplyee_ID}`)
            const res = await response.json();
            console.log(res);
            setFees(res.data.reverse())
            // const feesData = res.data;

            // // Calculate total paid balance
            // let totalPaidBalance = feesData.reduce((total, payment) => {
            //     // Check if the status is 'paid' and there is a start date
            //     if (payment.status === 'Paid' && payment.start) {
            //         // If fully paid, add the payment amount to the total
            //         return total + parseFloat(payment.amount);
            //     }
            //     // If partially paid, subtract the remaining amount and add to the total
            //     else if (payment.status === 'Partially paid' && payment.start) {
            //         const paidAmount = parseFloat(payment.amount) - parseFloat(payment.remaining_amount);
            //         console.log(paidAmount)
            //         return total + paidAmount;
            //     }
            //     return total; // Skip if payment doesn't meet the criteria
            // }, 0);

            // let totalRemainingAmount = feesData.reduce((total, payment) => {
            //     // Check if the status is 'Pending' or 'Partially Paid' and there is a start date
            //     if ((payment.status === 'Pending' || payment.status === 'Partially paid') && payment.start) {
            //         return total + parseFloat(payment.remaining_amount); // Add remaining amount
            //     }
            //     return total; // Skip if payment doesn't meet the criteria
            // }, 0);
            // let totalAmount = feesData.reduce((total, payment) => {
            //     // Check if the status is 'Pending' or 'Partially Paid' and there is a start date
            //     if ((payment.status === 'Pending' ||payment.status==="Paid" || payment.status === 'Partially paid') && payment.start) {
            //         return total + parseFloat(payment.amount); // Add remaining amount
            //     }
            //     return total; // Skip if payment doesn't meet the criteria
            // }, 0);
            // setRemaining(totalRemainingAmount)
            // console.log("Total Paid Balance: ", totalPaidBalance,totalAmount);
            // settotalPaid(totalPaidBalance)
            // setTotalAmount(totalAmount)
        } catch (error) {
            console.log(error);
        }
    }

    // Filter the payment data based on the input values
    const filteredFeesData = fees.filter((payment) => {

        const matchesStatus = filterStatus === '' || payment.status.toLowerCase() === filterStatus.toLowerCase();
        const matchesMonth = filterMonth === '' || new Date(payment.updatedAt).getMonth() + 1 === parseInt(filterMonth);
        console.log(matchesMonth, filterMonth)
        return matchesStatus && matchesMonth;
    });

    useEffect(() => {
        const calculateTotals = () => {
            let totalPaidBalance = 0;
            let totalRemainingAmount = 0;
            let totalBalanceAmount = 0;

            filteredFeesData.forEach(payment => {
                if (payment.status === 'Paid' && payment.start) {
                    totalPaidBalance += parseFloat(payment.amount);
                } else if (payment.status === 'Partially paid' && payment.start) {
                    const paidAmount = parseFloat(payment.amount) - parseFloat(payment.remaining_amount);
                    totalPaidBalance += paidAmount;
                }
                if ((payment.status === 'Pending' || payment.status === 'Partially paid') && payment.start) {
                    totalRemainingAmount += parseFloat(payment.remaining_amount);
                }
                if ((payment.status === 'Pending' || payment.status === 'Paid' || payment.status === 'Partially paid') && payment.start) {
                    totalBalanceAmount += parseFloat(payment.amount);
                }
            });

            settotalPaid(totalPaidBalance);
            setRemaining(totalRemainingAmount);
            setTotalAmount(totalBalanceAmount);
        };

        calculateTotals();
    }, [filteredFeesData]);

    console.log(filteredFeesData)
    useEffect(() => {
        PaymentdetailsFetch();
    }, [])

    return (
        <>
            {/* <Header /> */}
            <div className="payment-container">
                <div className="payment-summary">
                    <div className="summary-cards">
                        <div className="summary-card">
                            <div className="card-icon">
                                <i className="fas fa-money-bill-wave"></i>
                            </div>
                            <h4>Amount Total</h4>
                            <p>₹{paymentSummary.totalAmount.toFixed(2)}</p>
                            <p className="card-desc">Total payment required</p>
                        </div>
                        <div className="summary-card">
                            <div className="card-icon">
                                <i className="fas fa-check-circle"></i>
                            </div>
                            <h4>Amount Paid</h4>
                            <p>₹{paymentSummary.paidAmount.toFixed(2)}</p>
                            <p className="card-desc">Payment completed</p>
                            <div className="progress-bar">
                                <div className="progress" style={{ width: `${paymentSummary.completionPercentage}%` }}></div>
                            </div>
                        </div>
                        <div className="summary-card">
                            <div className="card-icon">
                                <i className="fas fa-dollar-sign"></i>
                            </div>
                            <h4>Amount Due</h4>
                            <p>₹{paymentSummary.dueAmount.toFixed(2)}</p>
                            <p className="card-desc">Remaining balance</p>
                        </div>
                    </div>
                    {/* <div className="payment-link">
                        <a href="#make-payment">Make payment</a>
                    </div> */}
                </div>

                {/* Filter Input Section */}
                <div className="filter-section">
                    {/* <input
                        type="text"
                        placeholder="Filter by name..."
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                    /> */}
                    <select
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                    >
                        <option value="">All Status</option>
                        <option value="Paid">Paid</option>
                        <option value="Partially paid">Partially Paid</option>
                        <option value="Pending">Pending</option>
                    </select>
                    <select
                        value={filterMonth}
                        onChange={(e) => setFilterMonth(e.target.value)}
                    >
                        <option value="">All Months</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                </div>

                {/* Payment Table Section */}
                <div className="payment-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Transaction</th>
                                <th>Amount</th>
                                <th>Remaining Amount</th>
                                <th>Month</th>
                                <th>Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredFeesData.length === 0 ? <tr>
                                    <td colSpan="6" style={{ textAlign: "center", padding: "20px" }}>No record found</td>
                                </tr> : <>
                                    {filteredFeesData.map((payment, index) => (
                                        <tr key={index}>
                                            <td>{payment.transaction_id}</td>
                                            <td>{payment.amount / 100}</td>
                                            <td>{payment.remaining_amount === null ? 0 : (payment.status === "Paid" && payment.remaining_amount === payment.amount) ? "0" : payment.remaining_amount / 100}</td>
                                            <td>{payment.start === null ? "N/A" : new Date(payment.start).toLocaleString('en-US', { month: 'short' })}</td>
                                            <td>{new Date(payment.updatedAt).toLocaleDateString()}</td>
                                            <td className={payment.status.toLowerCase()}>{payment.status}</td>
                                        </tr>
                                    ))}
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    );
};

export default PaymentComponent;
